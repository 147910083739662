import React from 'react'
import { Button } from 'antd'
import { MODAL_IDS } from 'util/constants'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import useModal from 'components/hooks/useModal'
import {RolesAndPermissions} from "../../../../../util/role-constants"

export function CreateCouponBatchTimeFramesButton({
    couponBatch,
}) {
    const { openModal } = useModal(MODAL_IDS.CREATE_COUPON_BATCH_TIME_FRAME, {
        entity: couponBatch,
        entityKey: 'couponBatch',
    })
    return (
        <HasPermissions roles={RolesAndPermissions.couponsBatch.update}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={openModal}>
                    Add timeframe
                </Button>
            </div>
        </HasPermissions>
    )
}
