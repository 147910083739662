import React from 'react'
import WithMe from 'components/pages/authorized/WithMe'
import { SidebarContainer } from './SidebarContainer'
import { GlobalSettingsSideBarMenu } from './GlobalSettingsSideBarMenu'

export function GlobalSettingsContainer({
    children,
    activeMenuItem,
}) {
    return (
        <WithMe>
            {({ role }) => (
                <SidebarContainer
                    sidebar={(
                        <GlobalSettingsSideBarMenu
                            userRole={role}
                            activeMenuItem={activeMenuItem}
                        />
                    )}
                >
                    {children}
                </SidebarContainer>
            )}
        </WithMe>
    )
}
