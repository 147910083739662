import React from 'react'
import Mount from 'components/util/Mount'
import { PaginationTableBottomBar } from 'components/util/table/PaginationTableBottomBar'
import { UsersTableTopBar } from 'components/pages/settings/users/table/UsersTableTopBar'
import WithUsers from '../WithUsers'
import { UsersTable } from './UsersTable'

const PAGE_SIZE = 15

export function UsersTableWithUsers({
    paginationKey,
}) {
    return (
        <WithUsers
            paginationKey={paginationKey}
            limit={PAGE_SIZE}
        >
            {({
                isFetching,
                currentPage,
                total,
                params,
                usersForCurrentPage,
                fetchUsers,
            }) => (
                <Mount
                    didMount={fetchUsers}
                >
                    <UsersTableTopBar
                        searchValue={params.search}
                        onSearchUsers={(search) => {
                            if (search === '') {
                                fetchUsers()
                            } else {
                                fetchUsers({ ...params, search })
                            }
                        }}
                    />
                    <UsersTable
                        users={usersForCurrentPage}
                        isFetching={isFetching}
                    />
                    <PaginationTableBottomBar
                        currentPage={currentPage}
                        total={total}
                        pageSize={params.limit || PAGE_SIZE}
                        onChangePage={(page) => fetchUsers({ ...params, page })}
                    />
                </Mount>
            )}
        </WithUsers>
    )
}
