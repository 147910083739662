/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React, {
    useEffect, useRef, useState,
} from "react"
import { Main } from  "components/layout/Main"
import "./bookingPlayerRegistrationPage.css"
import { Button, Spin, Modal } from "antd/es/index"
import { useParams } from "react-router-dom/dist/index"
import { buildLocaleByLegalEntityIdAndLanguageCode } from "util/localization"
import { toCanvas } from "qrcode"
import RenderIf from "util/renderif"
import { useBookingPlayerRegistration } from "./registered-player-hook/useBookingPlayerRegistration"
import { EditBookingRegisteredPlayerModal } from "./modals/editBookingRegisteredPlayerModal"
import { DeleteBookingRegisteredPlayerModal } from "./modals/deleteBookingRegisteredPlayerModal"
import { QrCodeModal } from "./modals/qrCodeModal"
import dayjs from "dayjs"
import {useActiveFilters} from "../../hooks/useActiveFilters"
import {CreateBookingRegisteredPlayerModal} from "./modals/createBookingRegisteredPlayerModal"

function BookingPlayerRegistrationPage() {
    const pathParams = useParams()
    const activeFilters = useActiveFilters()
    const venueId = activeFilters.venueId
    const bookingOrderNumber = pathParams.bookingOrderNumber

    const {
        booking,
        fetchStatus,
        postBookingRegisteredPlayer,
        patchBookingRegisteredPlayer,
        deleteBookingRegisteredPlayer,
        increasePlayerAmountBooking,
    } = useBookingPlayerRegistration(venueId, bookingOrderNumber)

    // if there's no data after fetching: show no data modal
    if (booking === null && fetchStatus === "idle") {
        return (
            <Modal
                title="Error"
                open={true}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <div>
                    <p>No data for this booking.</p>
                </div>
            </Modal>
        )
    }

    // if there are errors: show error modal
    if (fetchStatus === "error") {
        return (
            <Modal
                title="Error"
                open={true}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <div>
                    <p>Something went wrong, please try again later.</p>
                </div>
            </Modal>
        )
    }

    return (
        <Main>
            {
                fetchStatus === "fetching" ? (
                    <Spin className="booking-registered-players-spinner" size="large" />
                ) : (
                    <BookingRegisteredPlayersList
                        booking={booking}
                        bookingOrderNumber={bookingOrderNumber}
                        postBookingRegisteredPlayer={postBookingRegisteredPlayer}
                        patchBookingRegisteredPlayer={patchBookingRegisteredPlayer}
                        deleteBookingRegisteredPlayer={deleteBookingRegisteredPlayer}
                        increasePlayerAmountBooking={increasePlayerAmountBooking}
                    />
                )
            }

        </Main>
    )
}

/**
 * @typedef {Object} BookingRegisteredPlayersListProps
 * @property {BookingResource} booking
 * @property {string} bookingOrderNumber
 * @property {(firstName: string, lastName: string, email: string, nickname: string) => void} postBookingRegisteredPlayer
 * @property {(bookingCustomerProfileId: number, firstName: string, lastName: string, email: string, nickname: string) => void} patchBookingRegisteredPlayer
 * @property {(bookingCustomerProfileId: number) => void} deleteBookingRegisteredPlayer
 * @property {() => void} increasePlayerAmountBooking
*/

/**
 * @param {BookingRegisteredPlayersListProps} props
 */
function BookingRegisteredPlayersList(props) {
    const {
        booking,
        bookingOrderNumber,
        postBookingRegisteredPlayer,
        patchBookingRegisteredPlayer,
        deleteBookingRegisteredPlayer,
        // increasePlayerAmountBooking,
    } = props

    // console.log("data:", data)

    const canvasRef = useRef(null)

    // #region Edit registered player modal functions
    const [selectedPlayerToEditDetails, setSelectedPlayerToEditDetails] = useState({
        bookingCustomerProfileId: null,
        firstName: "",
        lastName: "",
        email: "",
        nickname: "",
        hasAccount: false,
    })
    const [isEditBookingRegisteredPlayerModalOpen, setIsEditBookingRegisteredPlayerModalOpen] = useState(false)

    const showEditBookingRegisteredPlayerModal = (
        bookingCustomerProfileId,
        newFirstName,
        newLastName,
        newEmail,
        newNickname,
        hasAccount,
    ) => {
        setSelectedPlayerToEditDetails(
            {
                bookingCustomerProfileId,
                firstName: newFirstName,
                lastName: newLastName,
                email: newEmail,
                nickname: newNickname,
                hasAccount: hasAccount,
            },
        )
        setIsEditBookingRegisteredPlayerModalOpen(true)
    }

    const onSubmitEditBookingRegisteredPlayer = async (
        bookingCustomerProfileId,
        firstName,
        lastName,
        email,
        nickname,
    ) => {
        try {
            await patchBookingRegisteredPlayer(
                bookingCustomerProfileId,
                firstName,
                lastName,
                email,
                nickname,
            )
        } catch (error) {
            return error
        }
    }

    const onCancelEditBookingRegisteredPlayer = () => {
        setIsEditBookingRegisteredPlayerModalOpen(false)
    }

    // #endregion Edit registered player modal functions

    // #region Delete registered player modal functions
    const [playerToDeleteDetails, setPlayerToDeleteDetails] = useState({
        bookingCustomerProfileId: null,
        firstName: "",
        lastName: "",
    })
    // const [bookingCustomerProfileIdToDelete, setBookingCustomerProfileIdToDelete] = useState(null)
    const [isDeleteBookingRegisteredPlayerModalOpen, setIsDeleteBookingRegisteredPlayerModalOpen] = useState(false)

    const showDeleteBookingRegisteredPlayerModal = (bookingCustomerProfileId, firstName, lastName) => {
        setPlayerToDeleteDetails(
            {
                bookingCustomerProfileId,
                firstName,
                lastName,
            },
        )
        setIsDeleteBookingRegisteredPlayerModalOpen(true)
    }

    const onSubmitDeleteRegisteredPlayer = async () => {
        try {
            await deleteBookingRegisteredPlayer(
                playerToDeleteDetails.bookingCustomerProfileId,
            )
        } catch (error) {
            return error
        }
    }

    const onCancelDeleteBookingRegisteredPlayer = () => {
        setIsDeleteBookingRegisteredPlayerModalOpen(false)
    }

    // #endregion Delete registered player modal functions

    // #region Register player modal functions
    const [isCreateBookingRegisteredPlayerModalOpen, setIsCreateBookingRegisteredPlayerModalOpen] = useState(false)

    // Register player modal functions
    const showCreateBookingRegisteredPlayerModal = () => {
        setIsCreateBookingRegisteredPlayerModalOpen(true)
    }

    // eslint-disable-next-line consistent-return
    const onSubmitCreateBookingRegisteredPlayer = async (firstName, lastName, email, nickname) => {
        try {
            await postBookingRegisteredPlayer(
                firstName,
                lastName,
                email,
                nickname,
            )
        } catch (error) {
            return error
        }
    }

    const onCancelCreateBookingRegisteredPlayer = () => {
        setIsCreateBookingRegisteredPlayerModalOpen(false)
    }

    // #endregion Register player modal functions

    // #region Qr code modal functions
    const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false)

    const showQrCodeModal = () => {
        setIsQrCodeModalOpen(true)
    }

    const onCloseQrCodeModal = () => {
        setIsQrCodeModalOpen(false)
    }

    // #endregion Qr code modal functions

    // build playerRegistration link
    console.log("Booking language:", booking.gameLanguageCode)
    const locale = buildLocaleByLegalEntityIdAndLanguageCode(booking.legalEntityId, booking.gameLanguageCode)
    const playerRegistrationLink = `${process.env.REACT_APP_CCW_URL}/${locale}/register-to-booking/${bookingOrderNumber}`

    // create qr code
    useEffect(() => {
        toCanvas(canvasRef.current, playerRegistrationLink)
    }, [canvasRef, playerRegistrationLink])

    // booking data
    const bookerFullName = booking.bookerFirstName + " " + booking.bookerLastName
    const game = booking.game.title
    const playerAmount = booking.playerAmount
    const startDateTime = dayjs(booking.start)
    const startTime = startDateTime.format("HH:mm")
    // const startTime = data.start.split("T")[1].slice(0, 5)
    const registeredPlayers = booking.customerProfiles
    const bookingRegisteredProfiles = booking.bookingCustomerProfiles
    const remainingPlayerAmount = playerAmount - booking.customerProfiles.length

    // #region Get remaining player slots
    const remainingSlots = []

    for (let i = 0; i < remainingPlayerAmount; i++) {
        const playerNumber = (remainingSlots.length + 1) + registeredPlayers.length
        remainingSlots.push(
            <OpenPlayerSlot
                key={i}
                showCreateBookingRegisteredPlayerModal={showCreateBookingRegisteredPlayerModal}
                playerNumber={playerNumber}
            />,
        )
    }

    // #endregion Get remaining player slots

    return (
        <div className="player-registration-container">
            <DeleteBookingRegisteredPlayerModal
                isModalOpen={isDeleteBookingRegisteredPlayerModalOpen}
                onSubmit={onSubmitDeleteRegisteredPlayer}
                onCancel={onCancelDeleteBookingRegisteredPlayer}
                playerDetails={playerToDeleteDetails}
            />
            <EditBookingRegisteredPlayerModal
                isModalOpen={isEditBookingRegisteredPlayerModalOpen}
                onSubmit={onSubmitEditBookingRegisteredPlayer}
                onCancel={onCancelEditBookingRegisteredPlayer}
                playerDetails={selectedPlayerToEditDetails}

            />
            <CreateBookingRegisteredPlayerModal
                isModalOpen={isCreateBookingRegisteredPlayerModalOpen}
                onSubmit={onSubmitCreateBookingRegisteredPlayer}
                onCancel={onCancelCreateBookingRegisteredPlayer}
            />
            <div className="top-container">
                <div className="text-container">
                    <h2 className="title">Player registration</h2>
                    <div className="team-leader-container">
                        <h3 className="team-leader-text">
                            <strong>Team leader:</strong>
                        </h3>
                        <p className="team-leader-name">{bookerFullName}</p>
                    </div>
                    <div className="booking-details-container">
                        <div className="detail-item">
                            <GameIcon />
                            <p className="detail-item-text">{game}</p>
                        </div>
                        <div className="detail-item">
                            <AmountIcon />
                            <p className="detail-item-text">{playerAmount}</p>
                        </div>
                        <div className="detail-item">
                            <TimeIcon />
                            <p className="detail-item-text">{startTime}</p>
                        </div>
                    </div>
                </div>
                <div className="register-options-container">
                    <div className="qr-code-container">
                        {/** Quick fix to show qr code */}
                        <RenderIf isTrue={isQrCodeModalOpen}>
                            <QrCodeModal
                                onCancel={onCloseQrCodeModal}
                                isModalOpen={isQrCodeModalOpen}
                                playerRegistrationLink={playerRegistrationLink}
                            />
                        </RenderIf>

                        <canvas
                            ref={canvasRef}
                            className="qr-code-canvas"
                            onClick={showQrCodeModal}
                        />
                    </div>
                    <Button
                        type="primary"
                        className="register-players-button"
                        href={playerRegistrationLink}
                        target="_blank"
                    >
                        Register players
                    </Button>
                </div>
            </div>
            <div className="registered-players-container">
                {
                    registeredPlayers.map((player) => {
                        // find the bookingCustomerProfile that matches the player
                        const bookingCustomerProfile = bookingRegisteredProfiles.find(
                            (brp) => brp.customerProfileId === player.id,
                        )

                        console.log("player:", player)

                        return (
                            <RegisteredPlayerSlot
                                key={player.id}
                                playerFirstName={player.firstName}
                                playerLastName={player.lastName}
                                playerEmail={player.email}
                                playerNickname={bookingCustomerProfile.nickname}
                                playerAccountId={player.customerProfileAccountId}
                                bookingCustomerProfileId={bookingCustomerProfile.id}
                                patchBookingRegisteredPlayer={patchBookingRegisteredPlayer}
                                deleteBookingRegisteredPlayer={deleteBookingRegisteredPlayer}
                                showDeleteBookingRegisteredPlayerModal={showDeleteBookingRegisteredPlayerModal}
                                showEditBookingRegisteredPlayerModal={showEditBookingRegisteredPlayerModal}
                            />
                        )
                    })
                }

                {
                    remainingSlots
                }

                {/* <AddPlayerAvatar onClick={increasePlayerAmountBooking} /> */}

            </div>
        </div>
    )
}

// Player slots

/**
 * @typedef {Object} RegisteredPlayerSlotProps
 * @property {number} bookingCustomerProfileId
 * @property {string} playerFirstName
 * @property {string} playerLastName
 * @property {string} playerEmail
 * @property {string} playerNickname
 * @property {number} playerAccountId
 * @property {() => void} showDeleteBookingRegisteredPlayerModal
 * @property {(bookingCustomerProfileId: number, firstName: string, lastName: string, email: string, nickname: string) => void} showEditBookingRegisteredPlayerModal
 * @definition Component that represents a registered player slot
*/

/**
 * @param {RegisteredPlayerSlotProps} RegisteredPlayerSlotProps
 */

function RegisteredPlayerSlot(RegisteredPlayerSlotProps) {
    const {
        bookingCustomerProfileId,
        playerFirstName,
        playerLastName,
        playerEmail,
        playerNickname,
        playerAccountId,
        showDeleteBookingRegisteredPlayerModal,
        showEditBookingRegisteredPlayerModal,
    } = RegisteredPlayerSlotProps

    function onPlayerEditClick() {
        showEditBookingRegisteredPlayerModal(
            bookingCustomerProfileId,
            playerFirstName,
            playerLastName,
            playerEmail,
            playerNickname,
            playerAccountId !== null,
        )
    }

    function onPlayerDeleteClick() {
        showDeleteBookingRegisteredPlayerModal(
            bookingCustomerProfileId,
            playerFirstName,
            playerLastName,
        )
    }

    return (
        <div className="avatar-container">
            <div
                className="avatar-picture"
                onClick={onPlayerEditClick}
            >
                <RegisteredAvatarPicture />
            </div>
            <div className="avatar-name-bin-container">
                <p className="player-name">
                    {playerNickname}
                </p>
                <BinIcon
                    onClick={onPlayerDeleteClick}
                />
            </div>
        </div>
    )
}

/**
 * @typedef {Object} OpenPlayerSlotProps
 * @property {()} showCreateBookingRegisteredPlayerModal
 * @property {number} playerNumber
 * @definition Component that represents an open player slot
*/

/**
 * @param {OpenPlayerSlotProps} OpenPlayerSlotProps
 */

function OpenPlayerSlot(OpenPlayerSlotProps) {
    const {
        showCreateBookingRegisteredPlayerModal,
        playerNumber,
    } = OpenPlayerSlotProps

    return (
        <div className="avatar-container">

            <div className="avatar-picture" onClick={showCreateBookingRegisteredPlayerModal}>
                <OpenAvatarPicture />
            </div>
            <div className="avatar-name-container">
                <p className="player-name">
                    Player {playerNumber}
                </p>
            </div>
        </div>

    )
}

// Icons

function RegisteredAvatarPicture() {
    return (
        <svg width={140} height={141} xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path
                    d="M70 141c38.66 0 70-31.564 70-70.5 0-34.803-25.04-63.717-57.966-69.462A69.961 69.961 0 0 0 70 0C31.34 0 0 31.564 0 70.5S31.34 141 70 141z"
                    fill="#92ccca"
                />
                <path
                    d="M49.27 110.471l-20.914 11.502c-1.227.675-2.33 1.504-3.356 2.408C37.193 134.747 52.933 141 70.13 141c17.071 0 32.711-6.16 44.87-16.384-1.121-.955-2.338-1.814-3.687-2.491l-22.395-11.288a8.618 8.618 0 0 1-4.721-7.702v-8.859c.63-.722 1.349-1.65 2.118-2.746 3.053-4.347 5.362-9.129 6.962-14.145 2.872-.893 4.988-3.568 4.988-6.747v-9.456c0-2.08-.917-3.939-2.343-5.24v-13.67S98.707 21 70.134 21C41.56 21 44.345 42.272 44.345 42.272v13.67c-1.428 1.301-2.343 3.16-2.343 5.24v9.456c0 2.491 1.298 4.683 3.243 5.95 2.343 10.285 8.48 17.688 8.48 17.688v8.64a8.63 8.63 0 0 1-4.455 7.555z"
                    fill="#239A98"
                    fillRule="nonzero"
                />
                <g
                    transform="translate(36 20)"
                    fill="#FFFFFF"
                >
                    <path
                        d="M26.73 17.448c.279.591.88 1 1.577 1h11.386c.697 0 1.298-.409 1.577-1h6.158c11.199 0 20.276 9.078 20.276 20.276S58.627 58 47.428 58H20.737C9.539 58 .46 48.922.46 37.724s9.078-20.276 20.276-20.276h5.993zm2.316 9.435a1.49 1.49 0 0 0 0 2.98h9.908a1.49 1.49 0 0 0 0-2.98h-9.908z"
                    />
                    <rect x="28.548" width="10.904" height="17" rx="3" />
                </g>
            </g>
        </svg>
    )
}

function OpenAvatarPicture() {
    return (
        <svg width={140} height={141} xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd"><path d="M70 141c38.66 0 70-31.564 70-70.5 0-34.803-25.04-63.717-57.966-69.462A69.961 69.961 0 0 0 70 0C31.34 0 0 31.564 0 70.5S31.34 141 70 141z" fill="#F0F1F1" />
                <path d="M48.27 110.471l-20.914 11.502c-1.227.675-2.33 1.504-3.356 2.408C36.193 134.747 51.933 141 69.13 141c17.071 0 32.711-6.16 44.87-16.384-1.121-.955-2.338-1.814-3.687-2.491l-22.395-11.288a8.618 8.618 0 0 1-4.721-7.702v-8.859c.63-.722 1.349-1.65 2.118-2.746 3.053-4.347 5.362-9.129 6.962-14.145 2.872-.893 4.988-3.568 4.988-6.747v-9.456c0-2.08-.917-3.939-2.343-5.24v-13.67S97.707 21 69.134 21C40.56 21 43.345 42.272 43.345 42.272v13.67c-1.428 1.301-2.343 3.16-2.343 5.24v9.456c0 2.491 1.298 4.683 3.243 5.95 2.343 10.285 8.48 17.688 8.48 17.688v8.64a8.63 8.63 0 0 1-4.455 7.555z" fill="#9B9B9B" fillRule="nonzero" />
            </g>
        </svg>
    )
}

// /**
//  * @typedef {Object} AddPlayerAvatarProps
//  * @property {() => void} onClick
//  */

// /**
//  * @param {AddPlayerAvatarProps} AddPlayerAvatarProps
// */

// function AddPlayerAvatar(AddPlayerAvatarProps) {
//     const { onClick } = AddPlayerAvatarProps

//     return (
//         <div className="add-player-avatar-container" onClick={onClick}>
//             <div className="add-player-avatar">
//                 <svg
//                     width="140"
//                     height="141"
//                     xmlns="http://www.w3.org/2000/svg"
//                 >
//                     <g fill="none" fillRule="evenodd"><path d="M70 141c38.66 0 70-31.564 70-70.5 0-34.803-25.04-63.717-57.966-69.462A69.961 69.961 0 0 0 70 0C31.34 0 0 31.564 0 70.5S31.34 141 70 141z" fill="#F0F1F1" />
//                         <path d="M48.27 110.471l-20.914 11.502c-1.227.675-2.33 1.504-3.356 2.408C36.193 134.747 51.933 141 69.13 141c17.071 0 32.711-6.16 44.87-16.384-1.121-.955-2.338-1.814-3.687-2.491l-22.395-11.288a8.618 8.618 0 0 1-4.721-7.702v-8.859c.63-.722 1.349-1.65 2.118-2.746 3.053-4.347 5.362-9.129 6.962-14.145 2.872-.893 4.988-3.568 4.988-6.747v-9.456c0-2.08-.917-3.939-2.343-5.24v-13.67S97.707 21 69.134 21C40.56 21 43.345 42.272 43.345 42.272v13.67c-1.428 1.301-2.343 3.16-2.343 5.24v9.456c0 2.491 1.298 4.683 3.243 5.95 2.343 10.285 8.48 17.688 8.48 17.688v8.64a8.63 8.63 0 0 1-4.455 7.555z" fill="#FFFFFF" fillRule="nonzero" />
//                     </g>
//                 </svg>
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width={60}
//                     height={60}
//                     viewBox="0 0 122.881 122.88"
//                     className="add-player-plus-icon"
//                     fill="#239A98"
//                 >
//                     <g>
//                         <path d="M56.573,4.868c0-0.655,0.132-1.283,0.37-1.859c0.249-0.6,0.61-1.137,1.056-1.583C58.879,0.545,60.097,0,61.44,0 c0.658,0,1.287,0.132,1.863,0.371c0.012,0.005,0.023,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.426,2.098,1.426,3.442c0,0.03-0.002,0.06-0.006,0.089v51.62l51.619,0c0.029-0.003,0.061-0.006,0.09-0.006 c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859c-0.248,0.6-0.609,1.137-1.057,1.583 c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37c-0.029,0-0.061-0.002-0.09-0.006l-51.619,0.001 v51.619c0.004,0.029,0.006,0.06,0.006,0.09c0,0.656-0.133,1.286-0.371,1.861c-0.006,0.014-0.012,0.025-0.018,0.037 c-0.248,0.584-0.602,1.107-1.037,1.543c-0.883,0.882-2.1,1.427-3.443,1.427c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.444-0.806-0.98-1.055-1.58h-0.001c-0.239-0.575-0.371-1.205-0.371-1.861 c0-0.03,0.002-0.061,0.006-0.09V66.303H4.958c-0.029,0.004-0.059,0.006-0.09,0.006c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.445-0.806-0.98-1.055-1.58H0.371C0.132,62.726,0,62.097,0,61.44 c0-0.655,0.132-1.283,0.371-1.859c0.249-0.6,0.61-1.137,1.056-1.583c0.881-0.881,2.098-1.426,3.442-1.426 c0.031,0,0.061,0.002,0.09,0.006l51.62,0l0-51.62C56.575,4.928,56.573,4.898,56.573,4.868L56.573,4.868z" />
//                     </g>
//                 </svg>
//             </div>
//             <p className="add-player-text">Add player</p>
//         </div>
//     )
// }

/**
 * @typedef {Object} BinIconProps
 * @property {() => void} onClick
 */

/**
 * @param {BinIconProps} BinIconProps
*/

function BinIcon(BinIconProps) {
    const { onClick } = BinIconProps
    return (
        <svg
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 408.483 408.483"
            color="red"
            className="bin-icon"
            onClick={onClick}
        >
            <g>
                <g>
                    <path d="M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z" />
                    <path d="M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z" />
                </g>
            </g>
        </svg>

    )
}

function GameIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 60"
            className="game-icon"
            width={20}
            height={20}
        >
            <path
                d="M26.58 33.51h7.95c.84-.02 1.64-.3 2.31-.81a4.06 4.06 0 0 0 1.58-2.67c.15-1.07-.12-2.14-.77-3l-.26-.31h-.02a3.65 3.65 0 0 0-.65-.59c-.66-.46-1.43-.71-2.24-.71H26.6c-.8 0-1.57.25-2.23.71-.34.25-.64.54-.88.87-.5.66-.77 1.44-.81 2.27v.17c0 1.03.37 2.01 1.07 2.76.73.79 1.73 1.25 2.81 1.3Zm-.81-4.04c0-.48.39-.88.87-.88h7.86c.46.01.83.39.82.91 0 .22-.08.43-.24.6l-.06.06a.85.85 0 0 1-.53.18h-7.88a.91.91 0 0 1-.37-.08.883.883 0 0 1-.48-.78ZM47.34 29.09l.26.18c.11.08.22.17.36.27l.12.09c1.21.94 2.21 2.16 2.88 3.53.67 1.37 1.03 2.91 1.04 4.44 0 .68-.07 1.37-.2 2.01l-.21.85c-.04.13-.06.27-.06.41 0 .61.39 1.15 1.07 1.36.08.01.16.02.23.02h.13c.61-.02 1.14-.43 1.32-1.02a12.806 12.806 0 0 0-.27-8.16c-.98-2.64-2.75-4.84-5.18-6.41l-.12-.08h-.18a1.5 1.5 0 0 0-.97.08c-.21.11-.4.26-.59.51-.14.23-.22.49-.22.76 0 .46.23.89.6 1.16Z"
            />
            <path
                d="M56.94 28.58a15.39 15.39 0 0 0-6.01-5.59 23.727 23.727 0 0 0-6.81-7.53c-2.7-1.92-5.84-3.27-9.09-3.92-.58-1.77-2.08-3.14-3.9-3.53-2.69-.59-5.33.95-6.15 3.53-3.26.64-6.39 1.99-9.09 3.92a23.727 23.727 0 0 0-6.81 7.53 15.437 15.437 0 0 0-6 5.59C1.59 31 .81 33.78.81 36.7c.05 4.13 1.69 8 4.64 10.9 2.92 2.88 6.79 4.46 10.97 4.46 2.87 0 5.34 0 7.39-.69 2.28-.77 3.85-2.36 4.96-4.99.16-.37.53-.62.96-.62.41 0 .78.24.93.63.59 1.37 1.33 2.45 2.27 3.29.86.77 1.87 1.34 3.08 1.72 2.2.69 4.7.69 7.67.69 4.11 0 7.99-1.59 10.92-4.48 2.95-2.9 4.59-6.78 4.63-10.92v-.1c0-2.84-.79-5.61-2.27-8.02ZM27.9 13.1v-.27c.02-.18.07-.35.14-.51.23-.53.64-.94 1.17-1.17 1.11-.49 2.41.02 2.9 1.12.07.16.12.33.14.49v8.13h-4.37l.04-7.78ZM14.36 24.46h.31c.42-.07.85-.11 1.26-.12h27.92c.49-.02 1 0 1.47.04.26.04.51.09.77.16l1.02.24c.7.19 1.39.45 2.05.77 4.23 2.15 6.92 6.43 7.02 11.16-.03 3.33-1.35 6.44-3.72 8.78a12.396 12.396 0 0 1-8.75 3.58c-2.81-.04-5 0-6.69-.5-1.67-.5-2.76-1.53-3.54-3.33-.64-1.49-2.1-2.46-3.81-2.46h-.02c-1.65 0-3.12.98-3.75 2.51-.76 1.85-1.69 2.8-3.18 3.29-1.51.49-3.53.49-6.32.49h-.05c-3.3 0-6.41-1.28-8.76-3.6a12.391 12.391 0 0 1-3.7-8.79c0-4.05 2-7.84 5.35-10.14.2-.14.4-.26.61-.39l.11-.07c1.21-.72 2.52-1.22 3.9-1.51l.51-.11Zm28.05-3.14h-7.1v-4.09c2.66.79 5.08 2.19 7.1 4.09Zm-24.73-.09c2.01-1.89 4.43-3.29 7.08-4.08v4.08h-7.08Z"
            />
        </svg>
    )
}

function AmountIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 38"
            className="profile-icon"
            width={16}
            height={16}
        >
            <path
                d="M37 16.5c-4.968 0-8.967 4.204-8.967 9.277 0 5.074 3.999 9.278 8.967 9.278s8.967-4.204 8.967-9.278c0-5.073-3.999-9.277-8.967-9.277Zm0 3c3.28 0 5.967 2.76 5.967 6.277 0 3.518-2.688 6.278-5.967 6.278-3.28 0-5.967-2.76-5.967-6.278 0-3.517 2.688-6.277 5.967-6.277Zm-6.533 20.334c-2.394 0-4.686.993-6.362 2.738-1.675 1.745-2.605 4.095-2.605 6.54V53a1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5v-3.889c0-1.681.644-3.288 1.77-4.46 1.125-1.173 2.63-1.817 4.197-1.817h13.066c1.567 0 3.072.644 4.197 1.816 1.126 1.173 1.77 2.78 1.77 4.461V53a1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5v-3.889c0-2.444-.93-4.794-2.605-6.539-1.676-1.745-3.968-2.738-6.362-2.738z"
                transform="translate(-21.5 -16.5)"
            />
        </svg>
    )
}

function TimeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="time-icon"
            width={16}
            height={16}
        >
            <path
                d="M12 24C5.38 24 0 18.62 0 12S5.38 0 12 0s12 5.38 12 12-5.38 12-12 12Zm0-21.82c-5.41 0-9.82 4.41-9.82 9.82s4.4 9.82 9.82 9.82 9.82-4.4 9.82-9.82S17.41 2.18 12 2.18Zm4.36 13.09c-.16 0-.33-.04-.49-.12l-4.36-2.18c-.37-.19-.6-.56-.6-.98V5.45c0-.6.49-1.09 1.09-1.09s1.09.49 1.09 1.09v5.87l3.76 1.88a1.086 1.086 0 0 1-.49 2.06Z"
            />
        </svg>
    )
}

export default BookingPlayerRegistrationPage
