/* eslint-disable max-len */
import React from "react"
import { DeleteOutlined } from "@ant-design/icons"
import {
    Table, Tag, Tooltip,
    Button,
} from "antd"
import {
    MODAL_IDS,
    APP_TIME_FORMAT,
    READABLE_DAY_OF_WEEK_DAY_MONTH_FORMAT,
} from "util/constants"
import { sorterToSortParam } from "util/index"
import ActiveFilters from "components/layout/filters/WithActiveFilters"
import { WithActiveModal }from "components/util/modals/WithActiveModal"
import HasPermissions from "components/pages/authorized/HasPermissions"
import { useNavigate } from "react-router"
import dayjs from "dayjs"
import ActivateGameSessionSwitch from "./ActivateGameSessionSwitch"
import {ROLES} from "../../../../util/role-constants"

const columns = [
    {
        title: "Active",
        dataIndex: "isActive",
        render: (text, record) => (
            <ActivateGameSessionSwitch gameSession={record} />
        ),
        onCell: () => ({
            onClick: (e) => e.stopPropagation(),
        }),
    },
    {
        title: "Day",
        dataIndex: "start",
        key: "start",
        sorter: true,
        width: 100,
        render: (text) => dayjs.utc(text).format(READABLE_DAY_OF_WEEK_DAY_MONTH_FORMAT),
    },
    {
        title: "Time",
        width: 130,
        render: (text, record) => (
            <span>
                {dayjs.utc(record.start).format(APP_TIME_FORMAT)}
                {" "}
                -
                {dayjs.utc(record.end).format(APP_TIME_FORMAT)}
            </span>
        ),
    },
    {
        title: "Bookings",
        dataIndex: "bookings",
        render: (text, record) => {
            if (!Array.isArray(record.bookings) || record.bookings.length === 0) {
                return null
            }

            return (
                <>
                    {
                        record.bookings.map((/** BookingResource */ booking) => (
                            <Tag key={booking.id} style={{ cursor: "pointer", userSelect: "none" }}>
                                {
                                    booking.comment ? (
                                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: booking.comment }} />}>
                                            <span role="img" aria-label={text}>
                                                ❗{booking.bookerCustomerProfile?.firstName} {booking.bookerCustomerProfile?.lastName}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        `${booking.bookerCustomerProfile?.firstName} ${booking.bookerCustomerProfile?.lastName}`
                                    )
                                }
                            </Tag>
                        ))
                    }
                </>
            )
        },
    },
    {
        title: "GameResource",
        dataIndex: "gameTitle",
        render: (text, record) => record.game.title,
    },
    {
        key: "roomId",
        title: "RoomResource",
        sorter: true,
        width: 100,
        dataIndex: "roomName",
        render: (text, record) => record.room.name,
    },
    {
        title: "Players",
        dataIndex: "playerAmount",
        align: "center",
        render: (text, record) => (
            <span>
                { `${record.registeredPlayerAmount} / ${record.playerAmount}` }
            </span>
        ),
    },
    {
        title: "Action",
        key: "action",
        align: "center",
        width: 80,
        render: (text, record) => (
            <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.HOST]}>
                <WithActiveModal
                    modalId={MODAL_IDS.DELETE_GAME_SESSION}
                    entity={record}
                >
                    {({ openModal }) => {
                        const isAttachedToBooking = record.status === "booked"
                        const DeleteButton = (
                            <Button
                                ghost
                                disabled={isAttachedToBooking}
                                icon={<DeleteOutlined />}
                                danger={true}
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                onClick={openModal}
                            />
                        )
                        if (isAttachedToBooking) {
                            return (
                                <Tooltip title="Only manually created game sessions can be removed.">
                                    {DeleteButton}
                                </Tooltip>
                            )
                        }
                        return DeleteButton
                    }}
                </WithActiveModal>
            </HasPermissions>
        ),
        onCell: () => ({
            onClick: (e) => e.stopPropagation(),
        }),
    },
]

function getColumns(activeRoomId) {
    if (activeRoomId === null)
        return columns

    return columns.filter(({ key }) => key !== "roomId")
}

function GameSessionsTable({
    gameSessions,
    isFetching,
    onSort,
}) {
    const navigate = useNavigate()

    return (
        <div>
            <ActiveFilters>
                {({ activeRoomId }) => (
                    <Table
                        rowKey="id"
                        rowClassName="cursor-pointer"
                        loading={isFetching}
                        dataSource={gameSessions}
                        columns={getColumns(activeRoomId)}
                        pagination={false}
                        onRow={(record) => ({
                            onClick: () => navigate(`/game-sessions/${record.id}`),
                        })}
                        onChange={(pagination, filters, sorter) => {
                            if (typeof sorter.columnKey !== "undefined")
                                onSort(sorterToSortParam(sorter))
                            else
                                onSort(null)
                        }}
                    />
                )}
            </ActiveFilters>
        </div>
    )
}

export default GameSessionsTable
