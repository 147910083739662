import React from "react"
import { Row } from "antd"
import { TopBar } from "components/layout/TopBar"
import { Main } from  "components/layout/Main"
import { GlobalSettingsContainer } from "components/layout/sidebar/GlobalSettingsContainer"
import SidebarContent from "components/layout/sidebar/SidebarContent"
import HasPermissions from "components/pages/authorized/HasPermissions"
import NotAuthorized from "components/pages/authorized/NotAuthorized"
import { VenuesTopBar } from "components/pages/settings/venues/VenuesTopBar"
import { VenuesOverview } from "components/pages/settings/venues/VenuesOverview"
import CreateVenueModalWithState from "./modals/create/CreateVenueModalWithState"
import EditVenueModalWithState from "./modals/edit/EditVenueModalWithState"
import { RolesAndPermissions } from "../../../../util/role-constants"

export function VenuesPage() {
    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="venues">
                    <SidebarContent>
                        <HasPermissions
                            roles={RolesAndPermissions.venues.read}
                            renderNotAuthorized={() => <NotAuthorized />}
                        >
                            <VenuesTopBar />
                            <Row>
                                <VenuesOverview paginationKey="overview" />
                            </Row>
                        </HasPermissions>
                    </SidebarContent>
                    <CreateVenueModalWithState />
                    <EditVenueModalWithState />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}
