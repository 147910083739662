import React from "react"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Col, Card, Button } from "antd"
import EditGameModalState from "components/pages/settings/games/modals/edit/EditGameModalState"
import { WithActiveModal }from "components/util/modals/WithActiveModal"
import WithMe from "components/pages/authorized/WithMe"
import { MODAL_IDS, PLAYER_AND_TOTAL } from "util/constants"
import { useAppState } from "components/hooks/useAppState"
import "./GameCard.css"
import {RolesAndPermissions} from "../../../../../util/role-constants"

const { Meta } = Card

export function GameCard({
    game,
    priceSystem,
}) {
    const { currencies } = useAppState()

    function getActions(roleSlug) {
        const actions = []
        const canEdit = RolesAndPermissions.games.update.indexOf(roleSlug) !== -1
        if (canEdit) {
            actions.push(
                <EditGameModalState key={1} game={game}>
                    {({ openModal }) => (
                        <EditOutlined onClick={openModal} />
                    )}
                </EditGameModalState>,
            )
        }

        const canDelete = RolesAndPermissions.games.delete.indexOf(roleSlug) !== -1
        if (canDelete) {
            actions.push(
                <WithActiveModal
                    key={2}
                    modalId={MODAL_IDS.DELETE_GAME}
                    entity={game}
                >
                    {({ openModal }) => (
                        <Button
                            icon={<DeleteOutlined />}
                            danger={true}
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            onClick={openModal}
                        />
                    )}
                </WithActiveModal>,
            )
        }

        return actions
    }

    const mapPrices = (priceJsonObject) => {
        if ((priceJsonObject !== null || priceJsonObject !== undefined || priceJsonObject !== "undefined") && typeof priceJsonObject === "object") {
            const arr = Object.keys(priceJsonObject)

            return arr.map((code, index) => {
                /**
                * TODO: Remove USD from games tables player_price if not needed.
                *
                * "Note: The USD currency symbol is used only if a legal entity is based in the US. As of 31/01/2024,
                * no current entity meets this criterion, but USD may appear in historical data
                * specifically in the "player_price" field of game tables."
                */
                if (code === "USD") {
                    const price = priceJsonObject[code]
                    const separator = index < arr.length - 1 ? ", " : ""
                    return `$${price}${separator}`
                } else {
                    const { currencySymbol } = currencies[code] ? currencies[code] : ""
                    const price = priceJsonObject[code]
                    const separator = index < arr.length - 1 ? ", " : ""
                    return `${currencySymbol}${price}${separator}`
                }
            })
        }

        return ""
    }

    return (
        <Col style={{ padding: 8 }} md={12} lg={8}>
            <WithMe>
                {({ role }) => (
                    <Card
                        cover={(
                            <img
                                style={{ height: 120, objectFit: "cover" }}
                                alt={game.title}
                                src={game.mediaItems[0] && game.mediaItems[0].urls.medium}
                            />
                        )}
                        actions={getActions(role.slug)}
                    >
                        <Meta
                            title={game.title}
                            description={(
                                <>
                                    <span className="game-card-row">
                                        <span className="game-card-label">Player price:</span>
                                        <span className="game-card-content">
                                            {mapPrices(game.playerPrice)}
                                        </span>
                                    </span>

                                    <span className="game-card-row">
                                        <span className="game-card-label">Players:</span>
                                        <span className="game-card-content">
                                            {game.minPlayers} - {game.maxPlayers}
                                        </span>
                                    </span>
                                    <span className="game-card-row">
                                        <span className="game-card-label">Duration:</span>
                                        <span className="game-card-content">
                                            {game.duration} minutes
                                        </span>
                                    </span>
                                    <span className="game-card-row">
                                        <span className="game-card-label">Teams:</span>
                                        <span className="game-card-content">
                                            {game.enableTeams ? game.numberOfTeams : "No teams"}
                                        </span>
                                    </span>
                                    <span className="game-card-row">
                                        <span className="game-card-label">Code:</span>
                                        <span className="game-card-content">
                                            {game.code}
                                        </span>
                                    </span>
                                    <span className="game-card-row">
                                        <span className="game-card-label">Min Age:</span>
                                        <span className="game-card-content">
                                            {game.minAge}
                                        </span>
                                    </span>
                                </>
                            )}
                        />
                    </Card>
                )}
            </WithMe>
        </Col>
    )
}
