/* eslint-disable max-len */
import React from 'react'
import { Alert } from 'antd'
import ActiveFilters from 'components/layout/filters/WithActiveFilters'
import Mount from 'components/util/Mount'
import ActiveBookingRequest from '../requests/ActiveBookingRequest'
import { buildLocaleByLegalEntityIdAndLanguageCode } from '../../../../util/localization'

function ActiveBookingInfo() {
    return (
        <ActiveFilters>
            {({ activeVenueId }) => (
                <ActiveBookingRequest venueId={activeVenueId}>
                    {({
                        isFetching,
                        fetchActiveBooking,
                        activeBooking,
                        activeBookingIds,
                    }) => (
                        <Mount
                            key={activeBookingIds.length}
                            didMount={fetchActiveBooking}
                        >
                            {(() => {
                                if (isFetching || activeBooking === null)
                                    return null

                                const localeCode = buildLocaleByLegalEntityIdAndLanguageCode(activeBooking.legalEntityId, activeBooking.gameLanguageCode)
                                const href = `${process.env.REACT_APP_CCW_URL}/${localeCode}/vr-booking/${activeBooking.orderNumber}/qrcode-register-link`

                                return (
                                    <Alert
                                        type="info"
                                        className="margin-bottom"
                                        message="Active booking"
                                        description={[
                                            <span key="hack">
                                                There is currently a booking active for this venue.&nbsp;
                                                <a rel="noreferrer noopener" target="_blank" href={href}>
                                                    Click here
                                                </a>
                                                {' '}
                                                to open the registration QR code page.
                                            </span>,
                                        ]}
                                    />
                                )
                            })()}
                        </Mount>
                    )}
                </ActiveBookingRequest>
            )}
        </ActiveFilters>
    )
}

export default ActiveBookingInfo
