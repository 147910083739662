import React from 'react'
import { SidebarContainer } from './SidebarContainer'
import VenueSettingsSideBarMenu from './VenueSettingsSideBarMenu'

function VenueSettingsContainer({
    children,
    activeMenuItem,
}) {
    return (
        <SidebarContainer
            sidebar={<VenueSettingsSideBarMenu activeMenuItem={activeMenuItem} />}
        >
            {children}
        </SidebarContainer>
    )
}

export default VenueSettingsContainer
