export const ROLES = {
    ADMIN: "admin",
    LEGAL_ENTITY_MANAGER: "legal-entity-manager",
    HOST: "host",
    READ_ONLY: "read-only",
    VENUE_MANAGER: "venue-manager",
    FRANCHISE: "franchise",
}

const ALL_ROLES = Object.values(ROLES)

// TODO: Should be replaced by a proper role & permissions object from the backend someday

/**
 * @typedef {string[]} AllowedRoleSlugs
 */

/**
 * @typedef {Object} CRUDRolesAndPermissions
 * @property {AllowedRoleSlugs} read User roles slugs that are allowed to read
 * @property {AllowedRoleSlugs} create User roles slugs that are allowed to create
 * @property {AllowedRoleSlugs} update User roles slugs that are allowed to update
 * @property {AllowedRoleSlugs} delete User roles slugs that are allowed to delete
 */

/**
 * @typedef {Object} RolesAndPermissions
 *
 * @property {AllowedRoleSlugs} bookingsTopBarTab
 * @property {AllowedRoleSlugs} gameSessionsTopBarTab
 * @property {AllowedRoleSlugs} venueSettingsTopBarTab
 * @property {AllowedRoleSlugs} analyticsTopBarTab
 * @property {AllowedRoleSlugs} settingsTopBarTab
 *
 * @property {CRUDRolesAndPermissions} coupons
 * @property {CRUDRolesAndPermissions} couponsBatch
 * @property {CRUDRolesAndPermissions} vouchers
 * @property {CRUDRolesAndPermissions} giftCards
 * @property {AllowedRoleSlugs} exports
 * @property {CRUDRolesAndPermissions} games
 * @property {CRUDRolesAndPermissions} venues
 * @property {CRUDRolesAndPermissions} legalEntities
 * @property {CRUDRolesAndPermissions} users
 */

/**
 * @type {RolesAndPermissions}
 */
export const RolesAndPermissions = {
    bookingsTopBarTab: ALL_ROLES,
    gameSessionsTopBarTab: ALL_ROLES,
    venueSettingsTopBarTab: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE],
    analyticsTopBarTab: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER],
    settingsTopBarTab: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE, ROLES.VENUE_MANAGER],

    coupons: {
        read: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE],
        create: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
        update: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
        delete: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
    },
    couponsBatch: {
        read: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
        create: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
        update: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
        delete: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
    },
    vouchers: {
        read: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE],
        create: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE, ROLES.VENUE_MANAGER],
        update: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE, ROLES.VENUE_MANAGER],
        delete: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE, ROLES.VENUE_MANAGER],
    },
    giftCards: {
        read: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE],
        create: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE, ROLES.VENUE_MANAGER],
        update: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
        delete: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
    },
    exports: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE],
    games: {
        read: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER],
        create: [ROLES.ADMIN],
        update: [ROLES.ADMIN],
        delete: [ROLES.ADMIN],
    },
    venues: {
        read: [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER],
        create: [ROLES.ADMIN],
        update: [ROLES.ADMIN],
        delete: [],
    },
    legalEntities: {
        read: [ROLES.ADMIN],
        create: [ROLES.ADMIN],
        update: [ROLES.ADMIN],
        delete: [],
    },
    users: {
        read: [ROLES.ADMIN],
        create: [ROLES.ADMIN],
        update: [ROLES.ADMIN],
        delete: [ROLES.ADMIN],
    },
}
