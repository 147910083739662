import React from "react"
import { TopBar } from "components/layout/TopBar"
import { Main } from  "components/layout/Main"
import { GlobalSettingsContainer } from "components/layout/sidebar/GlobalSettingsContainer"
import HasPermissions from "components/pages/authorized/HasPermissions"
import NotAuthorized from "components/pages/authorized/NotAuthorized"
import EditUserModalWithState from "./modals/edit/EditUserModalWithState"
import CreateUserModalWithRedux from "./modals/create/CreateUserModalWithRedux"
import DeleteUserModalWithRedux from "./modals/delete/DeleteUserModalWithRedux"
import {UsersTableWithUsers} from "./table/UsersTableWithUsers"
import {RolesAndPermissions} from "../../../../util/role-constants"

export function UsersPage() {
    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <HasPermissions
                    roles={RolesAndPermissions.users.read}
                    renderNotAuthorized={() => <NotAuthorized />}
                >
                    <GlobalSettingsContainer activeMenuItem="users">
                        <UsersTableWithUsers paginationKey="overview" />
                        <CreateUserModalWithRedux />
                        <DeleteUserModalWithRedux />
                        <EditUserModalWithState />
                    </GlobalSettingsContainer>
                </HasPermissions>
            </Main>
        </>
    )
}
