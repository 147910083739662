/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux'
import { fetchMe } from 'store/actions/me'
import { useCallback, useMemo } from 'react'
import {ROLES} from "../../util/role-constants"

/**
 * @typedef {Object} DefaultVenue
 * @property {number} id
 * @property {string} code
 * @property {string} name
 */

/**
 * @typedef {Object} Country
 * @property {string} code
 * @property {string} currencyCode
 * @property {boolean} isEu
 * @property {string} localizedName
 * @property {string} phoneCountryCode
 * @property {string} vatCountryCode
 * @property {string} vatFormat
 * @property {number} vatFormatMinLength
 * @property {number} vatFormatMaxLength
 */

/**
 * @typedef {Object} LegalEntity
 * @property {number} id
 * @property {string} name
 * @property {string} priceSystem Either "per_player" or "player_and_total"
 * @property {Country} coutnry
 */

/**
 * @typedef User
 * @property {number} id
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {number} role Id of user role
 * @property {DefaultVenue} defaultVenue Default venue of user
 * @property {number[]} venues Ids of venues user has access to
 * @property {LegalEntity[]} legalEntities Legal entities user has access to
 */

/**
 * @typedef ReturnObj
 * @property {boolean} hasFetchedUser
 * @property {() => void} handleFetchMe
 * @property {User | undefined} user
 * @property {boolean} isHost
 * @property {boolean} isAdmin
 * @property {boolean} isReadOnly
 * @property {boolean} isVenueManager
 * @property {boolean} isLegalEntityManager
 * @property {boolean} iseFranchise
 */

/**
 * Returns current user information if fetched
 * @returns {ReturnObj}
 */
export default function useCurrentUser() {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.me)
    const roles = useSelector(state => state.entities.roles)

    const currentUserRole = useMemo(() => {
        if (typeof currentUser === 'undefined' || currentUser === null)
            return undefined
        if (typeof roles === 'undefined' || roles === null)
            return undefined

        // console.log('currentUser:', currentUser)

        return roles[currentUser.role]
    }, [currentUser, roles])

    const handleFetchMe = useCallback(() => {
        return dispatch(fetchMe())
    }, [dispatch])

    if (currentUser === null || typeof currentUser === 'undefined') {
        return {
            hasFetchedUser: false,
            // isFetching: false,
            handleFetchMe: handleFetchMe,
            isHost: false,
            isAdmin: false,
            isReadOnly: false,
            isVenueManager: false,
            isLegalEntityManager: false,
        }
    } else {
        const roleSlug = currentUserRole.slug
        return {
            hasFetchedUser: true,
            user: currentUser,
            handleFetchMe: handleFetchMe,
            isHost: roleSlug === ROLES.HOST,
            isAdmin: roleSlug === ROLES.ADMIN,
            isReadOnly: roleSlug === ROLES.READ_ONLY,
            isVenueManager: roleSlug === ROLES.VENUE_MANAGER,
            isLegalEntityManager: roleSlug === ROLES.LEGAL_ENTITY_MANAGER,
            iseFranchise: roleSlug === ROLES.FRANCHISE,
        }
    }
}
