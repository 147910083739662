import React from 'react'
import { APP_DATE_FORMAT } from 'util/constants'
import dayjs from 'dayjs'
import CouponDetailItem from './CouponDetailItem'
import CouponDetailTitle from './CouponDetailTitle'
import CouponTimeFramesList from './CouponTimeFramesList'
import { CreateCouponTimeFramesButton } from './CreateCouponTimeFramesButton'
import './CouponDetails.css'

export function CouponDetails({ coupon }) {
    let cCreatedAt = null
    let cUsedAt = null
    let cCreator = null
    let cLastPossiblePlayDate = null
    let cMinPlayerAmount = null

    if (coupon) {
        cCreatedAt = dayjs.utc(coupon.createdAt).format(APP_DATE_FORMAT)
        cUsedAt = coupon.usedAt && dayjs.utc(coupon.usedAt).format(APP_DATE_FORMAT)
        cCreator = coupon.creator && `${coupon.creator.firstName} ${coupon.creator.lastName}`
        cLastPossiblePlayDate = coupon.lastPossiblePlayDate && dayjs.utc(coupon.lastPossiblePlayDate).format(APP_DATE_FORMAT)
        cMinPlayerAmount = coupon.minPlayerAmount
    }
    let usedText = cUsedAt || 'No'
    if (coupon.isMultiUse) {
        if (coupon.usageLimit !== null) {
            usedText = `${coupon.usedCount} out of ${coupon.usageLimit} times`
        } else {
            usedText = `${coupon.usedCount} time(s)`
        }
    }
    let availableText = 'Bookings'
    if (coupon.isAllowedForGiftcards) {
        availableText += ' and giftcards'
    }

    return (
        <div className="couponDetails">
            <div className="column-50">
                <CouponDetailTitle
                    emoji="🎁"
                    text="Details"
                />
                <ul>
                    <CouponDetailItem label="Used:">
                        {usedText}
                    </CouponDetailItem>
                    <CouponDetailItem label="Created at:">
                        {cCreatedAt}
                    </CouponDetailItem>
                    <CouponDetailItem label="Created by:">
                        {cCreator || 'No user set'}
                    </CouponDetailItem>

                    <CouponDetailItem label="Available in:">
                        {coupon.legalEntity !== null
                            && typeof coupon.legalEntity !== 'undefined'
                            && `${coupon.legalEntity.name}: `}
                        {coupon.venue === null ? 'All venues' : coupon.venue.name}
                    </CouponDetailItem>
                    <CouponDetailItem label="Available for:">
                        {availableText}
                    </CouponDetailItem>
                    <CouponDetailItem label="Description:">
                        {coupon.description}
                    </CouponDetailItem>
                    {
                        cLastPossiblePlayDate
                        && (
                            <CouponDetailItem label="Last possible play date:">
                                {cLastPossiblePlayDate}
                            </CouponDetailItem>
                        )
                    }
                    {
                        cMinPlayerAmount
                        && (
                            <CouponDetailItem label="Min player amount:">
                                {cMinPlayerAmount}
                            </CouponDetailItem>
                        )
                    }
                </ul>
            </div>
            <div className="column-50">
                <CouponDetailTitle
                    emoji="⏰"
                    text="Valid timeframes"
                />
                <CouponTimeFramesList coupon={coupon} />
                <CreateCouponTimeFramesButton coupon={coupon} />
            </div>
        </div>
    )
}
