import React from 'react'
import { Button } from 'antd'
import * as theme from 'styles/theme'
import { MODAL_IDS } from 'util/constants'
import TableTopBar from 'components/layout/tables/TableTopBar'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import useModal from 'components/hooks/useModal'
import {RolesAndPermissions} from "../../../../util/role-constants"

export function LegalEntitiesTopBar() {
    const { openModal } = useModal(MODAL_IDS.CREATE_LEGAL_ENTITY)

    return (
        <TableTopBar>
            <HasPermissions roles={RolesAndPermissions.legalEntities.create}>
                <Button
                    type="primary"
                    onClick={openModal}
                    style={{ marginLeft: `${theme.spacingSmaller}px` }}
                >
                    New legal entity
                </Button>
            </HasPermissions>
        </TableTopBar>
    )
}
