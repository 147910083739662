import { Main } from  "components/layout/Main"
import { TopBar } from "components/layout/TopBar"
import { GlobalSettingsContainer } from "components/layout/sidebar/GlobalSettingsContainer"
import HasPermissions from "components/pages/authorized/HasPermissions"
import NotAuthorized from "components/pages/authorized/NotAuthorized"
import React, { useState } from "react"
import { ROLES } from "util/constants"
import {
    Button, DatePicker, notification,
    Form,
} from "antd/es/index"
import "./exportsPage.css"
import { METHODS, callApiWithOptions } from "store/middleware/callApi"
import FileSaver from "file-saver"
import { useActiveFilters } from "components/hooks/useActiveFilters"
import useCurrentUser from "components/hooks/useCurrentUser"
import {RolesAndPermissions} from "../../../../util/role-constants"

const { RangePicker } = DatePicker

export function ExportsPage() {
    const [bookingsDateRange, setBookingsDateRange] = useState([null, null])
    const [bookingsDateRangeHasError, setBookingsDateRangeHasError] = useState(false)
    const [bookingsDateRangeError, setBookingsDateRangeError] = useState("")
    const { venueId } = useActiveFilters()
    const { user } = useCurrentUser()

    // Find the legal entity by active venue id
    const legalEntity = user.legalEntities.find((entity) => (
        entity.venues.find((venue) => venue.id === venueId)
    ))
    // Get selected venues from legal entity
    const selectedVenues = legalEntity.venues
    // Find the selected venue by id
    const selectedVenue = selectedVenues.find((venue) => venue.id === venueId)
    const selectedVenueName = selectedVenue.name
    const selectedVenueCode = selectedVenue.code

    function onBookingDateRangeChange(value) {
        if (value === null) {
            setBookingsDateRange([null, null])
            return
        }
        setBookingsDateRange(value)
    }

    const [orderPriceUpdatesDateRange, setOrderPriceUpdatesDateRange] = useState([null, null])
    const [orderPriceUpdatesDateRangeHasError, setOrderPriceUpdatesDateRangeHasError] = useState(false)
    const [orderPriceUpdatesDateRangeError, setOrderPriceUpdatesDateRangeError] = useState("")

    function onOrderPriceUpdateDateRangeChange(value) {
        if (value === null) {
            setOrderPriceUpdatesDateRange([null, null])
            return
        }
        setOrderPriceUpdatesDateRange(value)
    }

    async function downloadOrderPriceUpdates(dateRange) {
        setOrderPriceUpdatesDateRangeHasError(false)
        setOrderPriceUpdatesDateRangeError("")

        const startDate = dateRange[0]
        const endDate = dateRange[1]

        const queryParams = {
            startDate: startDate,
            endDate: endDate,
            venueId: venueId,
        }

        try {
            const response = await callApiWithOptions("/orderPriceUpdates/export", {
                method: METHODS.GET,
                params: queryParams,
                useJson: false,
            })

            const blob = await response.blob()
            const startDateWithoutHours = dateRange[0].startOf("day").format("DD-MM-YYYY")
            const endDateWithoutHours = endDate.endOf("day").format("DD-MM-YYYY")
            FileSaver.saveAs(blob, `${selectedVenueCode}_order_price_updates_from_${startDateWithoutHours}_to_${endDateWithoutHours}_export.xlsx`)
        } catch (error) {
            const json = await error.json()

            // Check for validation errors
            if (error.status === 422) {
                let errorToShow = ""
                setOrderPriceUpdatesDateRangeHasError(true)
                if (typeof json.errors.start_date !== "undefined") {
                    errorToShow += `${json.errors.start_date[0]} `
                    setOrderPriceUpdatesDateRangeError(errorToShow)
                }

                if (typeof json.errors.end_date !== "undefined") {
                    errorToShow += json.errors.end_date[0]
                    setOrderPriceUpdatesDateRangeError(errorToShow)
                }

                return
            }

            const count = Object.keys(json.errors).length

            for (let i = 0; i < count; i++) {
                const err = json.errors[Object.keys(json.errors)[i]]
                const errorMessage = `${err[0]}`
                notification.error({
                    message: "Something went wrong for order price updates export.",
                    description: errorMessage,
                })
            }
        }
    }

    async function downloadBookings(dateRange) {
        setBookingsDateRangeHasError(false)
        setBookingsDateRangeError("")

        const startDate = dateRange[0]
        const endDate = dateRange[1]

        const queryParams = {
            startDate: startDate,
            endDate: endDate,
            venueId: venueId,
        }

        try {
            const response = await callApiWithOptions("/bookings/export", {
                method: METHODS.GET,
                params: queryParams,
                useJson: false,
            })

            const blob = await response.blob()
            const startDateWithoutHours = startDate.startOf("day").format("DD-MM-YYYY")
            const endDateWithoutHours = endDate.endOf("day").format("DD-MM-YYYY")
            FileSaver.saveAs(blob, `${selectedVenueCode}_bookings_from_${startDateWithoutHours}_to_${endDateWithoutHours}_export.xlsx`)
        } catch (error) {
            const json = await error.json()

            // Check for validation errors
            if (error.status === 422) {
                let errorToShow = ""
                setBookingsDateRangeHasError(true)
                if (typeof json.errors.start_date !== "undefined") {
                    errorToShow += `${json.errors.start_date[0]} `
                    setBookingsDateRangeError(errorToShow)
                }

                if (typeof json.errors.end_date !== "undefined") {
                    errorToShow += json.errors.end_date[0]
                    setBookingsDateRangeError(errorToShow)
                }

                return
            }

            const count = Object.keys(json.errors).length

            for (let i = 0; i < count; i++) {
                const err = json.errors[Object.keys(json.errors)[i]]
                const errorMessage = `${err[0]}\n`
                notification.error({
                    message: "Something went wrong for bookings export.",
                    description: errorMessage,
                })
            }
        }
    }

    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="exports">
                    <HasPermissions
                        roles={RolesAndPermissions.exports}
                        renderNotAuthorized={() => <NotAuthorized />}
                    >
                        <div className="exports-container">
                            <h2>
                                <u>Exports for {selectedVenueName}</u>
                            </h2>
                            <div className="bookings-export-container">
                                <h3>
                                    Bookings:
                                </h3>
                                <div className="exports-form">
                                    <Form.Item
                                        validateStatus={bookingsDateRangeHasError ? "error" : ""}
                                        help={bookingsDateRangeError}
                                        style={{ width: "50%" }}
                                    >
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            value={bookingsDateRange}
                                            onCalendarChange={onBookingDateRangeChange}
                                            format="DD-MM-YYYY"
                                        />
                                    </Form.Item>
                                    <Button htmlType="submit" type="primary" onClick={() => downloadBookings(bookingsDateRange)}>Download</Button>
                                </div>
                            </div>
                            <div className="bookings-export-container">
                                <h3>
                                    Order updates:
                                </h3>
                                <div className="exports-form">
                                    <Form.Item
                                        validateStatus={orderPriceUpdatesDateRangeHasError ? "error" : ""}
                                        help={orderPriceUpdatesDateRangeError}
                                        style={{ width: "50%" }}
                                    >
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            value={orderPriceUpdatesDateRange}
                                            onCalendarChange={onOrderPriceUpdateDateRangeChange}
                                            format="DD-MM-YYYY"
                                        />
                                    </Form.Item>

                                    <Button htmlType="submit" type="primary" onClick={() => downloadOrderPriceUpdates(orderPriceUpdatesDateRange)}>Download</Button>
                                </div>
                            </div>
                        </div>

                    </HasPermissions>
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}
