import React from "react"
import Mount from "components/util/Mount"
import Loading from "components/util/Loading"
import Venues from "components/pages/settings/users/Venues"
import { VenueCard } from "components/pages/settings/venues/VenueCard"

const PAGE_SIZE = 15

export function VenuesOverview({
    paginationKey,
}) {
    return (
        <Venues
            paginationKey={paginationKey}
            limit={PAGE_SIZE}
        >
            {({
                isFetching,
                fetchVenues,
                venuesForCurrentPage,
            }) => (
                <Mount
                    didMount={async () => {
                        await fetchVenues()
                    }}
                >
                    {isFetching
                        ? <Loading className="full-width" />
                        : (
                            venuesForCurrentPage.map((venue) => (
                                <VenueCard
                                    key={venue.id}
                                    venue={venue}
                                />
                            ))
                        )}
                </Mount>
            )}
        </Venues>
    )
}
