import React, { useState } from "react"
import { Button, Input, Radio } from "antd"
import * as theme from "styles/theme"
import { MODAL_IDS, ROLES } from "util/constants"
import { WithActiveModal }from "components/util/modals/WithActiveModal"
import HasPermissions from "components/pages/authorized/HasPermissions"
import useDownload from "components/hooks/useDownload"
import "./GiftCardsTableTopBar.css"
import {RolesAndPermissions} from "../../../../../util/role-constants"

const { Search } = Input

export const ACTIVE_FILTER = "active"
export const INACTIVE_FILTER = "in_active"

export function GiftCardsTableTopBar({
    activeStatusFilter,
    onFilterGiftCards,
    onSearchGiftCards,
    searchValue,
}) {
    const [searchFilter, setSearchFilter] = useState(searchValue)
    const { startDownload: handleDownloadUnusedGiftCards, isDownloading } = useDownload({
        endpoint: "/gift-cards/export",
        saveAs: "gift-cards.xlsx",
    })

    return (
        <div className="flex justify-content-space-between margin-bottom">
            <div className="leftItems">
                <HasPermissions roles={RolesAndPermissions.giftCards.create}>
                    <WithActiveModal modalId={MODAL_IDS.CREATE_GIFT_CARD}>
                        {({ openModal }) => (
                            <Button
                                type="primary"
                                onClick={openModal}
                                style={{ marginRight: `${theme.spacing}px` }}
                            >
                                New gift card
                            </Button>
                        )}
                    </WithActiveModal>
                    <Button
                        onClick={handleDownloadUnusedGiftCards}
                        style={{ marginRight: `${theme.spacing}px` }}
                        loading={isDownloading}
                    >
                        Export unused gift cards
                    </Button>
                </HasPermissions>
            </div>
            <div className="rightItems">
                <div className="filterButtons">
                    <Radio.Group
                        value={activeStatusFilter}
                        onChange={(e) => onFilterGiftCards(e.target.value)}
                    >
                        <Radio.Button value={ACTIVE_FILTER}>Active</Radio.Button>
                        <Radio.Button value={INACTIVE_FILTER}>Inactive</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="searchContainer">
                    <Search
                        value={searchFilter}
                        placeholder="Type to search gift cards..."
                        onChange={((e) => setSearchFilter(e.target.value))}
                        onSearch={onSearchGiftCards}
                    />
                </div>
            </div>
        </div>
    )
}
