import React from 'react'
import { useSelector } from 'react-redux'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import { GlobalSettingsContainer } from 'components/layout/sidebar/GlobalSettingsContainer'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import NotAuthorized from 'components/pages/authorized/NotAuthorized'
import { MODAL_IDS } from 'util/constants'
import useModal from 'components/hooks/useModal'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import { CouponsBatchTableWithState } from './details/CouponsBatchTableWithState'
import CreateCouponBatchModal from './modals/create/CreateCouponBatchModal'
import CreateCouponBatchTimeFrameModal from './modals/create-batch-timeframes/CreateCouponBatchTimeFrameModal'
import EditCouponBatchTimeFrameModal from './modals/edit-batch-timeframes/EditCouponBatchTimeFrameModal'
import DeleteCouponBatchTimeFrameModal from './modals/delete-batch-timeframes/DeleteCouponBatchTimeFrameModal'
import EditCouponBatchModal from './modals/edit/EditCouponBatchModal'
import MarkCouponBatchUsedModal from './modals/edit/MarkCouponBatchUsedModal'
import {RolesAndPermissions} from "../../../../util/role-constants"

export function CouponsBatchPage() {
    const markUsedCouponBatchModal = useModal(MODAL_IDS.MARK_USED_COUPON_BATCH)
    const createCouponBatchModal = useModal(MODAL_IDS.CREATE_COUPON_BATCH)
    const createCouponBatchTimeFrameModal = useModal(MODAL_IDS.CREATE_COUPON_BATCH_TIME_FRAME)
    const editCouponBatchTimeFrameModal = useModal(MODAL_IDS.EDIT_COUPON_BATCH_TIME_FRAME)
    const editCouponBatchModal = useModal(MODAL_IDS.EDIT_COUPON_BATCH)
    const deleteCouponBatchTimeFrameModal = useModal(MODAL_IDS.DELETE_COUPON_BATCH_TIME_FRAME)
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CouponsBatchPage.name}`)
    const legalEntities = useSelector(({ entities }) => entities.legalEntities)

    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="couponsBatch">
                    <HasPermissions
                        roles={RolesAndPermissions.couponsBatch.read}
                        renderNotAuthorized={() => <NotAuthorized />}
                    >
                        <CouponsBatchTableWithState paginationKey="overview" />
                    </HasPermissions>

                    <CreateCouponBatchModal
                        isOpen={createCouponBatchModal.isOpen}
                        onClose={createCouponBatchModal.closeModal}
                        activeLegalEntity={legalEntity}
                        legalEntities={legalEntities}
                    />

                    <MarkCouponBatchUsedModal
                        isOpen={markUsedCouponBatchModal.isOpen}
                        onClose={markUsedCouponBatchModal.closeModal}
                    />

                    <CreateCouponBatchTimeFrameModal
                        isOpen={createCouponBatchTimeFrameModal.isOpen}
                        onClose={createCouponBatchTimeFrameModal.closeModal}
                        couponBatch={createCouponBatchTimeFrameModal.entity}
                    />
                    <EditCouponBatchTimeFrameModal
                        isOpen={editCouponBatchTimeFrameModal.isOpen}
                        onClose={editCouponBatchTimeFrameModal.closeModal}
                        couponTimeFrame={editCouponBatchTimeFrameModal.entity}
                    />
                    <EditCouponBatchModal
                        isOpen={editCouponBatchModal.isOpen}
                        onClose={editCouponBatchModal.closeModal}
                        legalEntities={legalEntities}
                        activeLegalEntity={legalEntity}
                        couponBatch={editCouponBatchModal.entity}
                    />
                    <DeleteCouponBatchTimeFrameModal
                        isOpen={deleteCouponBatchTimeFrameModal.isOpen}
                        onClose={deleteCouponBatchTimeFrameModal.closeModal}
                        couponTimeFrame={deleteCouponBatchTimeFrameModal.entity}
                    />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}
