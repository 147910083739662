import React, { useState } from 'react'
import { Button, Input } from 'antd'
import { MODAL_IDS } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import TableTopBar from 'components/layout/tables/TableTopBar'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import {RolesAndPermissions} from "../../../../../util/role-constants"

const { Search } = Input

export function UsersTableTopBar({ searchValue, onSearchUsers }) {
    const [searchFilter, setSearchFilter] = useState(searchValue)

    return (
        <TableTopBar>
            <HasPermissions roles={RolesAndPermissions.users.create}>
                <WithActiveModal modalId={MODAL_IDS.CREATE_USER}>
                    {({
                        openModal,
                    }) => (
                        <Button
                            type="primary"
                            onClick={openModal}
                        >
                            Invite user
                        </Button>
                    )}
                </WithActiveModal>
            </HasPermissions>
            <div className="rightItems">
                <div className="searchContainer">
                    <Search
                        value={searchFilter}
                        placeholder="Type to search users..."
                        onChange={((e) => setSearchFilter(e.target.value))}
                        onSearch={() => {
                            onSearchUsers(searchFilter)
                        }}
                    />
                </div>
            </div>
        </TableTopBar>
    )
}
