import {useCallback, useEffect, useState} from "react"
import callApi from "store/middleware/callApi"
import { METHODS } from "store/middleware/api"

/**
 * @typedef {Object} ReturnObj
 * @property {BookingResource} booking
 * @property {Object} errors
 * @property {string} fetchStatus
 * @property {(firstNameToPost: string, lastNameToPost: string, emailToPost: string, nicknameToPost: string) => void} postBookingRegisteredPlayer
 * @property {(bookingCustomerProfileID: number, firstNameToPost: string, lastNameToPost: string, emailToPost: string, nicknameToPost: string) => void} patchBookingRegisteredPlayer
 * @property {(bookingCustomerProfileID: number) => void} deleteBookingRegisteredPlayer
 * @property {() => void} increasePlayerAmountBooking
 */


/**
 * @param {string} venueId
 * @param {string} bookingOrderNumber
 * @returns {ReturnObj}
 * @definition Hook to handle the registration of players to a booking
 */
export function useBookingPlayerRegistration(venueId, bookingOrderNumber) {
    const [fetchStatus, setFetchStatus] = useState("idle")
    const [errors, setErrors] = useState(undefined)
    const [booking, setBooking] = useState(/** @type {BookingResource|null} */ null)

    const fetchBookingRegisteredPlayers = useCallback(async () => {
        setFetchStatus("fetching")

        try {
            const result = await callApi(
                METHODS.GET,
                `/venues/${venueId}/bookings/${bookingOrderNumber}`,
            )

            setBooking(result.data)
            setErrors(undefined)
            setFetchStatus("idle")
        } catch (error) {
            setErrors(error)
            setFetchStatus("error")
        }
    }, [bookingOrderNumber, venueId])

    /**
     * @property {string} firstNameToPost
     * @property {string} lastNameToPost
     * @property {string} emailToPost
     * @property {string} nicknameToPost
     * @definition Function to register a player to a booking
    */
    const postBookingRegisteredPlayer = useCallback(async (
        firstNameToPost,
        lastNameToPost,
        emailToPost,
        nicknameToPost,
    ) => {
        const requestBody = {
            firstName: firstNameToPost,
            lastName: lastNameToPost,
            email: emailToPost,
            nickname: nicknameToPost,
        }

        try {
            await callApi(
                METHODS.POST,
                `/bookings/${bookingOrderNumber}/register-profile`,
                undefined,
                undefined,
                requestBody,
            )

            setErrors(undefined)
            fetchBookingRegisteredPlayers()
        } catch (error) {
            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve("All data has been submitted successfully!")
        })
    }, [bookingOrderNumber, fetchBookingRegisteredPlayers])

    /**
     * @property {number} bookingCustomerProfileID
     * @property {string} firstNameToPost
     * @property {string} lastNameToPost
     * @property {string} emailToPost
     * @property {string} nicknameToPost
     * @definition Function to edit a registered player from a booking
    */
    const patchBookingRegisteredPlayer = useCallback(async (
        bookingCustomerProfileID,
        firstNameToPost,
        lastNameToPost,
        emailToPost,
        nicknameToPost,
    ) => {
        const requestBody = {
            firstName: firstNameToPost,
            lastName: lastNameToPost,
            email: emailToPost,
            nickname: nicknameToPost,
            countryId: 17,
            preferredLocaleCode: "be",
        }

        try {
            await callApi(
                METHODS.PATCH,
                `/bookings/${bookingOrderNumber}/registered-profiles/${bookingCustomerProfileID}`,
                undefined,
                undefined,
                requestBody,
            )

            setErrors(undefined)
            fetchBookingRegisteredPlayers()
        } catch (error) {
            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve("All data has been submitted successfully!")
        })
    }, [bookingOrderNumber, fetchBookingRegisteredPlayers])

    /**
     * @property {number} bookingCustomerProfileID
     * @definition Function to delete a registered player from a booking
    */
    const deleteBookingRegisteredPlayer = useCallback(async (
        bookingCustomerProfileID,
    ) => {
        try {
            await callApi(
                METHODS.DELETE,
                `/bookings/${bookingOrderNumber}/registered-profiles/${bookingCustomerProfileID}`,
                undefined,
                undefined,
                undefined,
            )

            setErrors(undefined)
            fetchBookingRegisteredPlayers()
        } catch (error) {
            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve("All data has been submitted successfully!")
        })
    }, [bookingOrderNumber, fetchBookingRegisteredPlayers])

    // const increasePlayerAmountBooking = async () => {
    //     try {
    //         await callApi(
    //             METHODS.POST,
    //             `/bookings/${bookingOrderNumber}/increase-players`,
    //             undefined,
    //             undefined,
    //             undefined,
    //         )

    //         setErrors(undefined)
    //         fetchBookingRegisteredPlayers()
    //     } catch (error) {
    //         setErrors(error)
    //         return new Promise((resolve, reject) => {
    //             reject(error)
    //         })
    //     }

    //     // eslint-disable-next-line no-unused-vars
    //     return new Promise((resolve, reject) => {
    //         resolve("All data has been submitted successfully!")
    //     })
    // }

    useEffect(() => {
        fetchBookingRegisteredPlayers()
    }, [bookingOrderNumber, fetchBookingRegisteredPlayers])

    return {
        errors: errors,
        booking: booking,
        fetchStatus,
        postBookingRegisteredPlayer,
        patchBookingRegisteredPlayer,
        deleteBookingRegisteredPlayer,
        // increasePlayerAmountBooking,
    }
}
