import React from 'react'
import FilterBarWithActiveFilters from 'components/layout/filters/FilterBarWithActiveFilters'
import Logo from 'components/util/Logo'
import WithMe from 'components/pages/authorized/WithMe'
import { TopBarMenu } from './TopBarMenu'
import TopBarUserMenu from './TopBarUserMenu'
import './TopBar.css'

/**
 * @component
 * @param {Object} props
 * @param {string=} props.activeMenuItem
 * @return {Element}
 */
export function TopBar(props) {
    return (
        <header className="header">
            <div className="max-width-container-with-padding headerContainer">
                <div className="logo">
                    <Logo />
                </div>
                <div className="menuContainer">
                    <WithMe>
                        {(result) => (
                            <TopBarMenu
                                userRole={result.role}
                                activeMenuItem={props.activeMenuItem}
                            />
                        )}
                    </WithMe>
                </div>
                <div className="itemsRight">
                    <div className="filterMenuContainer">
                        <FilterBarWithActiveFilters />
                    </div>
                    <div className="userMenuContainer">
                        <TopBarUserMenu />
                    </div>
                </div>
            </div>
        </header>
    )
}
