import React, { useEffect } from 'react'
import { usePaginatedVouchers } from '../../../../hooks/usePaginatedVouchers'
import { VouchersTableTopBar } from './VouchersTableTopBar'
import { VouchersTable } from './VouchersTable'
import { PaginationTableBottomBar } from '../../../../util/table/PaginationTableBottomBar'

/**
 * @param {Object} props
 * @param {string} props.currentUserRoleSlug
 * @param {() => void} props.openCreateModal
 * @param {(coupon: Object) => void} props.openEditModal
 * @param {(coupon: Object) => void} props.openDeleteModal
 * @param {Object[]} props.voucherIssueReasons
 * @param {Object[]} props.legalEntities
 * @param {Object[]} props.venues
 */
export function VouchersTableWithState(props) {
    const vouchersPagination = usePaginatedVouchers('overview')

    // console.log('Table with state props:', props)

    useEffect(() => {
        // Initial fetch
        if (vouchersPagination.initialFetchStarted === false && vouchersPagination.isFetching === false) {
            vouchersPagination.fetchVouchers({})
        }
    }, [vouchersPagination])

    return (
        <>
            <VouchersTableTopBar
                currentUserRoleSlug={props.currentUserRoleSlug}
                openCreateModal={props.openCreateModal}
                searchValue={vouchersPagination.paginationParams.search}
                onSearch={(search) => {
                    vouchersPagination.fetchVouchers({ ...vouchersPagination.paginationParams, search: search })
                }}
            />
            <VouchersTable
                vouchers={vouchersPagination.vouchersForCurrentPage}
                isFetching={vouchersPagination.isFetching}
                currentUserRoleSlug={props.currentUserRoleSlug}
                openEditModal={props.openEditModal}
                openDeleteModal={props.openDeleteModal}
                voucherIssueReasons={props.voucherIssueReasons}
                legalEntities={props.legalEntities}
                venues={props.venues}
            />
            <PaginationTableBottomBar
                currentPage={vouchersPagination.currentPage}
                total={vouchersPagination.totalPages}
                pageSize={vouchersPagination.perPage}
                onChangePage={(page) => vouchersPagination.fetchVouchers({ ...vouchersPagination.paginationParams, page: page })}
            />
        </>
    )
}
