import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Authorized from "components/pages/authorized/Authorized"
import GameSessionsPage from "components/pages/gameSessions/GameSessionsPage"
import RoomsPage from "components/pages/settings/rooms/RoomsPage"
import EquipmentSetsPage from "components/pages/settings/equipment-sets/EquipmentSetsPage"
import DiscountBannersPage from "components/pages/settings/discount-banners/DiscountBannersPage"
import { UsersPage } from "components/pages/settings/users/UsersPage"
import { CouponsPage } from "components/pages/settings/coupons/CouponsPage"
import {CouponsBatchPage} from "components/pages/settings/coupons-batch/CouponsBatchPage"
import { GamesPage } from "components/pages/settings/games/GamesPage"
// import BlockedSlotsPage from "components/pages/settings/blocked-slots/BlockedSlotsPageWithState"
import VenueGamesPage from "components/pages/settings/venue-games/VenueGamesPage"
import { GiftCardsPage } from "components/pages/settings/gift-cards/GiftCardsPage"
import { VenuesPage } from "components/pages/settings/venues/VenuesPage"
import CumulDashboard from "components/pages/analytics/CumulDashboard"
import { LegalEntitiesPageWithState } from "components/pages/settings/legal-entities/LegalEntitiesPageWithState"
import ChildIfPermitted from "components/util/ChildIfPermitted"
import TimeSlotsPage from "components/pages/settings/time-slots/TimeSlotsPage"
import BookingPlayerRegistrationPage from "components/pages/player-registration/bookingPlayerRegistrationPage"
import {VouchersPage } from "components/pages/settings/vouchers/VouchersPage"
import { NotFoundRedirectRoute } from "../util/NotFoundRedirectRoute"
import {GameSessionPage} from "../pages/gameSessions/GameSessionPage"
import {BookingsPage} from "../pages/bookings/BookingsPage"
import {ExportsPage } from "../pages/settings/exports/exportsPage"
import {ROLES, RolesAndPermissions} from "../../util/role-constants"

export function AuthorizedRoutes() {
    return (
        <BrowserRouter>
            <Authorized>
                <Routes>
                    <Route
                        path="/"
                        index={true}
                        element={(
                            <ChildIfPermitted roles="*">
                                <BookingsPage />
                            </ChildIfPermitted>
                        )}
                    />

                    <Route
                        path="/game-sessions"
                        element={(
                            <ChildIfPermitted roles="*">
                                <GameSessionsPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/game-sessions/:gameSessionId"
                        element={(
                            <ChildIfPermitted roles="*">
                                <GameSessionPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/bookings/:bookingOrderNumber/player-registration"
                        element={(
                            <ChildIfPermitted roles="*">
                                <BookingPlayerRegistrationPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/users"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.users.read} redirectTo="/settings/coupons">
                                <UsersPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/time-slots"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE]}>
                                <TimeSlotsPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/rooms"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE]}>
                                <RoomsPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/venue-games"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.FRANCHISE]}>
                                <VenueGamesPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/equipment-sets"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                                <EquipmentSetsPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/discount-banners"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                                <DiscountBannersPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/vouchers"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.vouchers.read}>
                                <VouchersPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/coupons"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.coupons.read}>
                                <CouponsPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/coupons/batch"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.couponsBatch.read}>
                                <CouponsBatchPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/gift-cards"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.giftCards.read}>
                                <GiftCardsPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/games"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.games.read} redirectTo="/settings/coupons">
                                <GamesPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/venues"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.venues.read} redirectTo="/settings/coupons">
                                <VenuesPage />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/settings/legal-entities"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.legalEntities.read} redirectTo="/settings/coupons">
                                <LegalEntitiesPageWithState />
                            </ChildIfPermitted>
                        )}
                    />

                    <Route
                        path="/settings/exports"
                        element={(
                            <ChildIfPermitted roles={RolesAndPermissions.exports} redirectTo="/settings/coupons">
                                <ExportsPage />
                            </ChildIfPermitted>
                        )}
                    />

                    <Route
                        path="/analytics/sales"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                                <CumulDashboard dashboardId="6be22df8-f016-4608-9cc5-1c91bd3012aa" activeMenuItem="sales" />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/analytics/marketing"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                                <CumulDashboard dashboardId="a453b7d5-e535-4833-a62f-af65f653bfa3" activeMenuItem="marketing" />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/analytics/venue-operations"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                                <CumulDashboard dashboardId="7dff92b6-e776-4055-afad-033b070edfde" activeMenuItem="venueOperations" />
                            </ChildIfPermitted>
                        )}
                    />
                    <Route
                        path="/analytics/venue-operations"
                        element={(
                            <ChildIfPermitted roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                                <CumulDashboard dashboardId="5a6b0bcc-d030-450e-9030-72974e4532e1" activeMenuItem="venueOccupation" />
                            </ChildIfPermitted>
                        )}
                    />

                    <Route path="*" element={<NotFoundRedirectRoute redirectTo="/" />} />
                </Routes>
            </Authorized>
        </BrowserRouter>
    )
}
