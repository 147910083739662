import React from "react"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Table, Button, Tag } from "antd"
import { MODAL_IDS } from "util/constants"
import { WithActiveModal }from "components/util/modals/WithActiveModal"
import Entity from "components/util/Entity"
import WithMe from "components/pages/authorized/WithMe"
import EditUserModalState from "components/pages/settings/users/modals/edit/EditUserModalState"
import { RolesAndPermissions } from "../../../../../util/role-constants"

const columns = [
    {
        title: "Name",
        key: "name",
        render: (text, record) => `${record.firstName} ${record.lastName}`,
        width: 200,
    },
    {
        title: "Email",
        dataIndex: "email",
        width: 220,
    },
    {
        title: "Role",
        dataIndex: "role",
        render: (text) => (
            <Entity entityKey="roles" id={text}>
                {({ label }) => (
                    `${label}`
                )}
            </Entity>
        ),
        width: 125,
    },
    {
        title: "Default Venues",
        dataIndex: "venues",
        render: (text, record) => {
            if (record.defaultVenue !== null && typeof record.defaultVenue !== "undefined") {
                return (
                    <Entity entityKey="venues" id={record.defaultVenue.id}>
                        {({ name }) => (
                            <Tag>{name}</Tag>
                        )}
                    </Entity>
                )
            } else {
                return null
            }
        },
    },
    {
        title: "Action",
        key: "action",
        align: "right",
        width: 80,
        render: (text, user) => (
            <WithMe>
                {({
                    role,
                }) => (
                    <>
                        <EditUserModalState user={user}>
                            {({ openModal }) => (
                                <Button
                                    ghost
                                    icon={<EditOutlined />}
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    disabled={RolesAndPermissions.users.update.indexOf(role.slug) === -1}
                                    onClick={openModal}
                                />
                            )}
                        </EditUserModalState>
                        <WithActiveModal
                            modalId={MODAL_IDS.DELETE_USER}
                            entity={user}
                        >
                            {({ openModal }) => (
                                <Button
                                    ghost
                                    icon={<DeleteOutlined />}
                                    danger={true}
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    disabled={RolesAndPermissions.users.delete.indexOf(role.slug) === -1}
                                    onClick={openModal}
                                />
                            )}
                        </WithActiveModal>
                    </>
                )}
            </WithMe>
        ),
    },
]

function expandedRowRender(users) {
    return (
        <>
            <p>All Venues attached: </p>
            <div>
                {
                    Array.isArray(users?.venues) && users.venues.map((id) => (
                        <Entity key={id} entityKey="venues" id={id}>
                            {({ name }) => (
                                <Tag>{name}</Tag>
                            )}
                        </Entity>
                    ))
                }
            </div>
        </>
    )
}

export function UsersTable({
    users,
    isFetching,
}) {
    return (
        <Table
            rowKey="id"
            columns={columns}
            expandable={{
                expandedRowRender: expandedRowRender,
                expandRowByClick: true,
                expandIcon: () => null,
            }}
            loading={isFetching}
            dataSource={users}
            pagination={false}
        />
    )
}
