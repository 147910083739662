import React, { useState } from 'react'
import { Button, Input, Radio } from 'antd'
import * as theme from 'styles/theme'
import { MODAL_IDS, ROLES } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import './CouponsTableTopBar.css'
import {RolesAndPermissions} from "../../../../../util/role-constants"

const { Search } = Input

export const ACTIVE_FILTER = 'active'
export const INACTIVE_FILTER = 'in_active'

/**
 * @param {Object} props
 * @param {string | undefined} props.activeStatusFilter
 * @param {(value: string) => void} props.onFilterCoupons
 * @param {(value: string) => void } props.onSearchCoupons
 * @param {string | undefined} props.searchValue
 * @returns {React.JSX.Element}
 * @constructor
 */
export function CouponsTableTopBar(props) {
    const [searchValue, setSearchValue] = useState(props.searchValue)

    return (
        <div className="flex justify-content-space-between margin-bottom">
            <div className="leftItems">
                <HasPermissions roles={RolesAndPermissions.coupons.create}>
                    <WithActiveModal modalId={MODAL_IDS.CREATE_COUPON}>
                        {({ openModal }) => (
                            <Button
                                type="primary"
                                onClick={openModal}
                                style={{ marginRight: `${theme.spacing}px` }}
                            >
                                New coupon
                            </Button>
                        )}
                    </WithActiveModal>
                </HasPermissions>
            </div>
            <div className="rightItems">
                <div className="filterButtons">
                    <Radio.Group
                        value={props.activeStatusFilter}
                        onChange={(e) => props.onFilterCoupons(e.target.value)}
                    >
                        <Radio.Button value={ACTIVE_FILTER}>Active</Radio.Button>
                        <Radio.Button value={INACTIVE_FILTER}>Inactive</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="searchContainer">
                    <Search
                        value={searchValue}
                        placeholder="Type to search coupons..."
                        onChange={((e) => setSearchValue(e.target.value))}
                        onSearch={props.onSearchCoupons}
                    />
                </div>
            </div>
        </div>
    )
}
