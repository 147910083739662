import React from "react"
import { Link } from "react-router-dom"
import {
    CalculatorOutlined,
    GiftOutlined,
    ShopOutlined,
    TeamOutlined,
    WalletOutlined,
    BankOutlined,
} from "@ant-design/icons"
import { Menu } from "antd"
import "./GlobalSettingsSideBarMenu.css"
import {ROLES, RolesAndPermissions} from "../../../util/role-constants"

export function GlobalSettingsSideBarMenu({
    userRole,
    activeMenuItem,
}) {
    const menuItemsGroup = {
        type: "group",
        label: (<h3>Settings</h3>),
        key: "thePark",
        children: [],
    }
    const menuItems = [menuItemsGroup]
    const roleSlug = userRole.slug

    if (RolesAndPermissions.vouchers.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/vouchers">
                    <WalletOutlined />
                    {" "}
                    Vouchers
                </Link>
            ),
            key: "vouchers",
        })
    }
    if (RolesAndPermissions.coupons.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/coupons">
                    <WalletOutlined />
                    {" "}
                    Coupons
                </Link>
            ),
            key: "coupons",
        })
    }
    if (RolesAndPermissions.couponsBatch.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/coupons/batch">
                    <WalletOutlined />
                    {" "}
                    Coupons batch
                </Link>
            ),
            key: "couponsBatch",
        })
    }
    if (RolesAndPermissions.giftCards.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/gift-cards">
                    <GiftOutlined />
                    {" "}
                    Gift cards
                </Link>
            ),
            key: "giftCards",
        })
    }
    if (RolesAndPermissions.games.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/games">
                    <CalculatorOutlined />
                    {" "}
                    Games
                </Link>
            ),
            key: "games",
        })
    }
    if (RolesAndPermissions.venues.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/venues">
                    <ShopOutlined />
                    {" "}
                    Venues
                </Link>
            ),
            key: "venues",
        })
    }
    if (RolesAndPermissions.legalEntities.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/legal-entities">
                    <BankOutlined />
                    {" "}
                    Legal Entities
                </Link>
            ),
            key: "legalEntities",
        })
    }
    if (RolesAndPermissions.users.read.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/users">
                    <TeamOutlined />
                    {" "}
                    Users
                </Link>
            ),
            key: "users",
        })
    }
    if (RolesAndPermissions.exports.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/exports">
                    <BankOutlined />
                    {" "}
                    Exports
                </Link>
            ),
            key: "exports",
        })
    }

    return (
        <div className="settingsSideBarMenu">
            <div className="adminThePark">
                <Menu
                    mode="vertical"
                    selectedKeys={[activeMenuItem]}
                    style={{ borderRight: 0 }}
                    items={menuItems}
                />
            </div>
        </div>
    )
}
