import React from "react"
import dayjs from "dayjs"
import CouponBatchTimeFramesList from "./CouponBatchTimeFramesList"
import CouponDetailItem from "../../coupons/details/CouponDetailItem"
import CouponDetailTitle from "../../coupons/details/CouponDetailTitle"
import { CreateCouponBatchTimeFramesButton } from "../components/CreateCouponBatchTimeFramesButton"
import { APP_DATE_FORMAT } from "../../../../../util/constants"
import "./CouponBatchDetails.css"

export function CouponBatchDetails({
    couponBatch,
}) {
    let cCreatedAt = null
    let cCreator = null
    let cLastPossiblePlayDate = null
    let cMinPlayerAmount = null
    if (couponBatch) {
        cCreatedAt = dayjs.utc(couponBatch.createdAt).format(APP_DATE_FORMAT)
        cCreator = couponBatch.creator && `${couponBatch.creator.firstName} ${couponBatch.creator.lastName}`
        cLastPossiblePlayDate = couponBatch.lastPossiblePlayDate && dayjs.utc(couponBatch.lastPossiblePlayDate).format(APP_DATE_FORMAT)
        cMinPlayerAmount = couponBatch.minPlayerAmount
    }
    let availableText = "Bookings"
    if (couponBatch.isAllowedForGiftcards) {
        availableText += " and giftcards"
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="CouponBatchDetailsColumn">
                    <CouponDetailTitle
                        emoji="🎁"
                        text="Details"
                    />
                    <ul style={{ listStyle: "none", padding: 0 }}>
                        <CouponDetailItem label="Code:">
                            {couponBatch.code}
                        </CouponDetailItem>
                        <CouponDetailItem label="Total:">
                            {couponBatch.couponCount}
                        </CouponDetailItem>
                        <CouponDetailItem label="Used:">
                            {couponBatch.usedCouponCount}
                        </CouponDetailItem>
                        <CouponDetailItem label="Expired:">
                            {couponBatch.expiredCouponCount}
                        </CouponDetailItem>
                        <CouponDetailItem label="Created at:">
                            {cCreatedAt}
                        </CouponDetailItem>
                        {
                            cLastPossiblePlayDate
                            && (
                                <CouponDetailItem label="Last possible play date:">
                                    {cLastPossiblePlayDate}
                                </CouponDetailItem>
                            )
                        }
                        {
                            cMinPlayerAmount
                            && (
                                <CouponDetailItem label="Min player amount:">
                                    {cMinPlayerAmount}
                                </CouponDetailItem>
                            )
                        }
                        <CouponDetailItem label="Created by:">
                            {cCreator || "No user set"}
                        </CouponDetailItem>
                        <CouponDetailItem label="Available in:">
                            {couponBatch.legalEntity !== null
                                && typeof couponBatch.legalEntity !== "undefined"
                                && `${couponBatch.legalEntity.name}: `}
                            {couponBatch.venue === null ? "All venues" : couponBatch.venue.name}
                        </CouponDetailItem>
                        <CouponDetailItem label="Available for:">
                            {availableText}
                        </CouponDetailItem>
                        <CouponDetailItem label="Description:">
                            {couponBatch.description}
                        </CouponDetailItem>
                    </ul>
                </div>
                <div className="CouponBatchDetailsColumn">
                    <CouponDetailTitle
                        emoji="⏰"
                        text="Valid timeframes"
                    />
                    <CouponBatchTimeFramesList couponBatch={couponBatch} />
                    <CreateCouponBatchTimeFramesButton couponBatch={couponBatch} />
                </div>
            </div>
        </div>
    )
}
