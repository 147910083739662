import React from 'react'
import HasPermissions from '../../../authorized/HasPermissions'
import { ROLES } from '../../../../../util/constants'
import { Button, Input } from 'antd'
import * as theme from '../../../../../styles/theme'
import {RolesAndPermissions} from "../../../../../util/role-constants"

const { Search } = Input

/**
 * @param {Object} props
 * @param {string} props.currentUserRoleSlug
 * @param {(value: string) => void } props.onSearch
 * @param {string | undefined} props.searchValue
 * @param {() => void} props.openCreateModal
 * @returns {React.JSX.Element}
 * @constructor
 */
export function VouchersTableTopBar(props) {
    const [searchValue, setSearchValue] = React.useState(props.searchValue)

    return (
        <div className="flex justify-content-space-between margin-bottom">
            <div className="leftItems">
                <HasPermissions roles={RolesAndPermissions.vouchers.create}>
                    <Button
                        type="primary"
                        onClick={props.openCreateModal}
                        style={{ marginRight: `${theme.spacing}px` }}
                    >
                        New voucher
                    </Button>
                </HasPermissions>
            </div>
            <div className="rightItems">
                <div className="searchContainer">
                    <Search
                        value={searchValue}
                        placeholder="Type to search vouchers..."
                        onChange={((e) => setSearchValue(e.target.value))}
                        onSearch={props.onSearch}
                    />
                </div>
            </div>
        </div>
    )
}
