/* eslint-disable max-len */
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import {
    BarChartOutlined,
    BarsOutlined,
    RocketOutlined,
    SettingOutlined,
    EnvironmentOutlined,
} from "@ant-design/icons"
import { Menu } from "antd"
import {ROLES, RolesAndPermissions} from "../../util/role-constants"

// https://ant.design/components/menu/#When-To-Use

/**
 * @component
 * @param {Object} props
 * @param {RoleResource} props.userRole
 * @param {string|undefined} props.activeMenuItem
 * @return {React.Element}
 */
export function TopBarMenu(props) {
    const items = useMemo(() => {
        const menuItems = []
        const roleSlug = props.userRole.slug

        if (RolesAndPermissions.bookingsTopBarTab.indexOf(roleSlug) !== -1) {
            menuItems.push({
                label: (
                    <Link to="/">
                        <BarsOutlined />
                        {" "}
                        Bookings
                    </Link>
                ),
                key: "bookings",
            })
        }
        if (RolesAndPermissions.gameSessionsTopBarTab.indexOf(roleSlug) !== -1) {
            menuItems.push({
                label: (
                    <Link to="/game-sessions">
                        <RocketOutlined />
                        {" "}
                        Game sessions
                    </Link>
                ),
                key: "gameSessions",
            })
        }
        if (RolesAndPermissions.venueSettingsTopBarTab.indexOf(roleSlug) !== -1) {
            menuItems.push({
                label: (
                    <Link to="/settings/rooms">
                        <EnvironmentOutlined />
                        {" "}
                        Venue
                    </Link>
                ),
                key: "venueSettings",
            })
        }
        if (RolesAndPermissions.analyticsTopBarTab.indexOf(roleSlug) !== -1) {
            menuItems.push({
                label: (
                    <Link to="/analytics/sales">
                        <BarChartOutlined />
                        {" "}
                        Analytics
                    </Link>
                ),
                key: "analytics",
            })
        }
        if (RolesAndPermissions.settingsTopBarTab.indexOf(roleSlug) !== -1) {
            let path
            switch (roleSlug) {
                case ROLES.FRANCHISE: {
                    path = "/settings/exports"
                    break
                }
                default: {
                    path = "/settings/vouchers"
                }
            }

            menuItems.push({
                label: (
                    <Link to={path}>
                        <SettingOutlined />
                        {" "}
                        Settings
                    </Link>
                ),
                key: "globalSettings",
            })
        }

        return menuItems
    }, [props.userRole.slug])

    return (
        <Menu
            style={{ borderBottom: "none" }}
            selectedKeys={[props.activeMenuItem]}
            mode="horizontal"
            items={items}
        />
    )
}
