import React, { useState, useEffect } from "react"
import { PaginationTableBottomBar } from "components/util/table/PaginationTableBottomBar"
import { MODAL_IDS } from "util/constants"
import useModal from "components/hooks/useModal"
import useDownload from "components/hooks/useDownload"
import { CouponsBatchTableTopBar, ACTIVE_FILTER } from "./CouponsBatchTableTopBar"
import { CouponsBatchTable } from "./CouponsBatchTable"
import useCouponBatches from "../hooks/useCouponBatches"

const PAGE_SIZE = 10

export function CouponsBatchTableWithState({
    paginationKey,
}) {
    const [activeStatusFilter, setActiveStatus] = useState(ACTIVE_FILTER)
    const {
        isFetching,
        currentPage,
        total,
        params,
        couponsForCurrentPage,
        fetchCouponBatches,
    } = useCouponBatches(paginationKey, PAGE_SIZE)
    const { openModalWithEntity } = useModal(MODAL_IDS.DELETE_COUPON_BATCH)
    const { startDownloadWithOptions, isDownloading } = useDownload({})
    const editCouponBatchModal = useModal(MODAL_IDS.EDIT_COUPON_BATCH)

    useEffect(() => {
        fetchCouponBatches({ ...params, status: activeStatusFilter })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStatusFilter])

    return (
        <>
            <CouponsBatchTableTopBar
                activeStatusFilter={activeStatusFilter}
                onFilterCoupons={setActiveStatus}
                onSearchCoupons={(search) => {
                    if (search === "") {
                        fetchCouponBatches()
                    } else {
                        fetchCouponBatches({ ...params, search })
                    }
                }}
                searchValue={params.search}
            />
            <CouponsBatchTable
                coupons={couponsForCurrentPage}
                isFetching={isFetching}
                onSort={(sort) => {
                    if (sort !== null) {
                        fetchCouponBatches({ ...params, sort })
                    } else {
                        fetchCouponBatches({ ...params })
                    }
                }}
                onEdit={editCouponBatchModal}
                onDelete={openModalWithEntity}
                onDownload={startDownloadWithOptions}
                isDownloading={isDownloading}
            />
            <PaginationTableBottomBar
                currentPage={currentPage}
                total={total}
                pageSize={params.limit || PAGE_SIZE}
                onChangePage={(page) => fetchCouponBatches({ ...params, page })}
            />
        </>
    )
}
