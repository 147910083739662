import React from 'react'
import { Row } from 'antd'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import NotAuthorized from 'components/pages/authorized/NotAuthorized'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import { GlobalSettingsContainer } from 'components/layout/sidebar/GlobalSettingsContainer'
import Loading from 'components/util/Loading'
import {LegalEntitiesTopBar} from "./LegalEntitiesTopBar"
import {LegalEntityCard} from "./LegalEntityCard"
import {EditLegalEntityModalWithState} from "./modals/edit/EditLegalEntityModalWithState"
import {CreateLegalEntityModalWithState} from "./modals/create/CreateLegalEntityModalWithState"
import { RolesAndPermissions } from "../../../../util/role-constants"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isFetching
 * @param {LegalEntityResource[]} props.legalEntities
 * @param {() => void} props.reFetch
 */
export function LegalEntitiesPage(props) {
    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="legalEntities">
                    <HasPermissions
                        roles={RolesAndPermissions.legalEntities.read}
                        renderNotAuthorized={() => <NotAuthorized />}
                    >
                        <LegalEntitiesTopBar />
                        <Row>
                            {
                                props.isFetching ? (
                                    <Loading className="full-width" />
                                ) : (
                                    props.legalEntities.map(le => (
                                        <LegalEntityCard key={le.id} legalEntity={le} />
                                    ))
                                )
                            }
                        </Row>
                    </HasPermissions>
                    <CreateLegalEntityModalWithState onComplete={props.reFetch} />
                    <EditLegalEntityModalWithState onComplete={props.reFetch} />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}
