import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import { GlobalSettingsContainer } from 'components/layout/sidebar/GlobalSettingsContainer'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import NotAuthorized from 'components/pages/authorized/NotAuthorized'
import CreateGiftCardModalWithState from './modals/create/CreateGiftCardModalWithState'
import { GiftCardsTableWithGiftCards } from './table/GiftCardsTableWithGiftCards'
import DeleteGiftCardModalWithRedux from './modals/delete/DeleteGiftCardModalWithRedux'
import EditGiftCardModalWithState from './modals/edit/EditGiftCardModalWithState'
import {RolesAndPermissions} from "../../../../util/role-constants"

export function GiftCardsPage() {
    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="giftCards">
                    <HasPermissions
                        roles={RolesAndPermissions.giftCards.read}
                        renderNotAuthorized={() => <NotAuthorized />}
                    >
                        <GiftCardsTableWithGiftCards paginationKey="overview" />
                    </HasPermissions>
                    <CreateGiftCardModalWithState />
                    <DeleteGiftCardModalWithRedux />
                    <EditGiftCardModalWithState />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}
