import React from 'react'
import { OverviewWithGames } from 'components/pages/settings/games/overview/OverviewWithGames'
import { Row } from 'antd'
import { GamesTableTopBar } from '../GamesTopBar'

export function GamesOverview() {
    return (
        <>
            <GamesTableTopBar />
            <Row>
                <OverviewWithGames paginationKey="overview" />
            </Row>
        </>
    )
}
