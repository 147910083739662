import React from "react"
import Mount from "components/util/Mount"
import Loading from "components/util/Loading"
import { useLegalEntity } from "components/hooks/useLegalEntity"
import WithGames from "../WithGames"
import { GameCard } from "../cards/GameCard"

const PAGE_SIZE = 100

export function OverviewWithGames({ paginationKey }) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === "undefined")
        throw new Error(`Legal Entity is undefined! Comp: ${OverviewWithGames.name}`)

    return (
        <WithGames paginationKey={paginationKey} limit={PAGE_SIZE}>
            {({ fetchGames, isFetching, gamesForCurrentPage }) => (
                <Mount
                    didMount={() => {
                        fetchGames()
                    }}
                >
                    {isFetching
                        ? <Loading className="full-width" />
                        : (gamesForCurrentPage.map((game) => (
                            <GameCard
                                game={game}
                                key={game.id}
                                priceSystem={legalEntity.priceSystem}
                            />
                        )))}
                </Mount>
            )}
        </WithGames>
    )
}
