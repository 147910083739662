export const LANGUAGES = {
    nl: "nl",
    fr: "fr",
    en: "en",
    pt: "pt",
}

/** @type {string[]} */
export const LANGUAGE_CODES = Object.values(LANGUAGES)

export const REASON_CODE = {
    other: "OTH1",
}

// TODO: For future the addons product should be refactored to be more robust.
export const EXTRA_INFORMATOIN_KEYS = {
    catering: "catering",
    meetingRoom: "meeting_room",
    barForFait: "bar_forfait",
    food: "food", // food here is being used for 1h open bar. This was caused by implementing "quick" add feature for the addons product.
    drinks: "drinks",
    drinkTokens: "drink_tokens",
    nachos: "nachos",
}
export const LANGUAGES_READABLE = {
    nl: "Dutch",
    fr: "French",
    en: "English",
    pt: "Portuguese",
}

export const LANGUAGES_ARRAY = Object.keys(LANGUAGES)

export const LANGUAGES_OBJECTS_ARRAY = Object.entries(LANGUAGES_READABLE).map((entry) => ({
    code: entry[0],
    name: entry[1],
}))

export const ERRORS = {
    AMOUNT: "Add a valid amount",
    BATCH_AMOUNT: "Add a valid number",
    CODE: "Add a valid code",
    DESCRIPTION: "Add a valid description",
    EMAIL: "Please provide a valid email",
    EXPIRATION_DATE: "Add a valid expiration date",
    FIRST_NAME: "Please provide a valid first name",
    LAST_NAME: "Please provide a valid last name",
    NAME: "Add a valid name",
    ROLE: "Choose a valid role",
    VENUES: "Please provide at least one venue",
    VENUE: "Please select a venue",
    LEGAL_ENTITY: "Please select a legal entity",
    OTHER_REASON: "Please add description for the other reason",
}

export const MODAL_IDS = {
    BLOCK_SLOTS: "BlockSlotsModal",
    CHANGE_SLOTS: "ChangeSlotsModal",
    CREATE_BOOKING: "CreateBookingModal",
    CREATE_COUPON: "CreateCouponModal",
    MARK_USED_COUPON_BATCH: "MarkUsedCouponBatchModal",
    CREATE_COUPON_BATCH: "CreateCouponBatchModal",
    CREATE_COUPON_TIME_FRAME: "CreateCouponTimeFrameModal",
    CREATE_COUPON_BATCH_TIME_FRAME: "CreateCouponBatchTimeFrameModal",
    CREATE_EQUIPMENT_SET: "CreateEquipmentSetModal",
    CREATE_GAME: "CreateGameModal",
    CREATE_PRICE_ADJUSTMENT: "CreatePriceAdjustment",
    CREATE_ROOM: "CreateRoomModal",
    CREATE_GIFT_CARD: "CreateGiftCardModal",
    CREATE_USER: "CreateUserModal",
    CREATE_VENUE: "CreateVenueModal",
    CREATE_VENUE_GAME: "CreateVenueGameModal",
    CREATE_VOUCHER: "CreateVoucherModal",
    CREATE_DISCOUNT_BANNER: "CreateDiscountBannerModal",
    CREATE_LEGAL_ENTITY: "CreateLegalEntityModal",
    DELETE_BOOKING: "DeleteBookingModal",
    AUDIT_BOOKING: "AuditBookingModal",
    EDIT_COUPON: "EditCouponModal",
    DELETE_COUPON: "DeleteCouponModal",
    DELETE_COUPON_BATCH: "DeleteCouponBatchModal",
    DELETE_COUPON_TIME_FRAME: "DeleteCouponTimeFrameModal",
    DELETE_COUPON_BATCH_TIME_FRAME: "DeleteCouponBatchTimeFrameModal",
    DELETE_EQUIPMENT_SET: "DeleteEquipmentSetModal",
    DELETE_GAME: "DeleteGameModal",
    DELETE_GAME_SESSION: "DeleteGameSessionModal",
    DELETE_GIFT_CARD: "DeleteGiftCardModal",
    DELETE_PRICE_ADJUSTMENT: "DeletePriceAdjustmentModal",
    DELETE_USER: "DeleteUserModal",
    DELETE_VENUE_GAME: "DeleteVenueGameModal",
    DELETE_VOUCHER: "DeleteVoucherModal",
    DELETE_DISCOUNT_BANNER: "DeleteDiscountBannerModal",
    DELETE_LEGAL_ENTITY: "DeleteLegalEntityModal",
    DETAILS_BOOKING: "BookingDetailsModal",
    EDIT_BOOKING: "EditBookingModal",
    EDIT_COUPON_BATCH: "EditCouponBatchModal",
    EDIT_COUPON_TIME_FRAME: "EditCouponTimeFrameModal",
    EDIT_COUPON_BATCH_TIME_FRAME: "EditCouponBatchTimeFrameModal",
    EDIT_GAME: "EditGameModal",
    EDIT_GIFT_CARD: "EditGiftCardModal",
    EDIT_PRICE_ADJUSTMENT: "EditPriceAdjustmentModal",
    EDIT_ROOM: "EditRoomModal",
    EDIT_USER: "EditUserModal",
    EDIT_VENUE: "EditVenueModal",
    EDIT_DISCOUNT_BANNER: "EditDiscountBannerModal",
    EDIT_LEGAL_ENTITY: "EditLegalEntityModal",
    EDIT_VOUCHER: "EditVoucherModal",
    TOGGLE_ACTIVE_GAME_SESSION: "ToggleActiveGameSessionModal",
    OUTSTANDING_AMOUNT_MODAL: "OutstandingAmountModal",
    REGISTER_PLAYER: "RegisterPlayerModal",
}


export const WEEK_DAYS = [{
    id: 0,
    name: "monday",
}, {
    id: 1,
    name: "tuesday",
}, {
    id: 2,
    name: "wednesday",
}, {
    id: 3,
    name: "thursday",
}, {
    id: 4,
    name: "friday",
}, {
    id: 5,
    name: "saturday",
}, {
    id: 6,
    name: "sunday",
}]

export const PLAYER_AND_TOTAL = "player_and_total"
export const PER_PLAYER = "per_player"

export const roomModes = {
    all: { text: "Best fit", value: "all" },
    singleRoom: { text: "Single room", value: "single_room" },
    doubleRoom: { text: "Double room", value: "double_room" },
}

export const APP_DATE_FORMAT = "YYYY-MM-DD"
export const APP_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm"
export const SHORT_DAY_OF_WEEK_YEAR_MONTH_DATE_FORMAT = "dd YY-MM-DD"
export const SHORT_DAY_OF_WEEK_DAY_MONTH_YEAR_FORMAT = "dd DD-MM-YY"
export const READABLE_DAY_OF_WEEK_DAY_MONTH_FORMAT = "dd MM-DD"
export const APP_MONTH_DAY_FORMAT = "MM-DD"
export const APP_TIME_FORMAT = "HH:mm"

export const BACKEND_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const BACKEND_DATE_FORMAT = "YYYY-MM-DD"
export const BACKEND_TIME_FORMAT = "HH:mm:ss"

/** @type {number} Minimum length of customer first name */
export const MIN_FIRST_NAME_LENGTH = 2
/** @type {number} Maximum length of customer first name */
export const MAX_FIRST_NAME_LENGTH = 64

/** @type {number} Minimum length of customer last name */
export const MIN_LAST_NAME_LENGTH = 2
/** @type {number} Maximum length of customer last name */
export const MAX_LAST_NAME_LENGTH = 128

/** @type {number} Minimum length of customer nickname */
export const MIN_NICKNAME_LENGTH = 2
/** @type {number} Maximum length of customer nickname */
export const MAX_NICKNAME_LENGTH = 20

export const MAX_UPLOAD_FILE_MB = 10
/** @type {number} Maximum amount of file bytes we can upload to the backend */
export const MAX_UPLOAD_FILE_BYTES = 1024 * 1024 * MAX_UPLOAD_FILE_MB