import React from 'react'
import { Button } from 'antd'
import * as theme from 'styles/theme'
import { MODAL_IDS } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import TableTopBar from 'components/layout/tables/TableTopBar'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import {RolesAndPermissions} from "../../../../util/role-constants"

export function GamesTableTopBar() {
    return (
        <TableTopBar>
            <HasPermissions roles={RolesAndPermissions.games.create}>
                <WithActiveModal modalId={MODAL_IDS.CREATE_GAME}>
                    {({ openModal }) => (
                        <Button
                            type="primary"
                            onClick={openModal}
                            style={{ marginLeft: theme.spacingSmaller }}
                        >
                            New game
                        </Button>
                    )}
                </WithActiveModal>
            </HasPermissions>
        </TableTopBar>
    )
}
